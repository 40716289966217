// icons
.icons {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 24px;
  vertical-align: middle;
  width: 24px;
}

.robo-icon {
  background-image: url('../img/icons/robo.png');
  height: 20px;
  width: 20px;
}
.union-icon {
  background-image: url('../img/icons/Union.png');
  height: 20px;
  width: 20px;
}

.email-icon {
  background-image: url('../img/icons/email.png');
  height: 20px;
  width: 20px;
}
.phone-icon {
  background-image: url('../img/icons/phone.png');
  height: 20px;
  width: 20px;
}
.divide-dot {
  background-image: url('../img/icons/divide-dot.png');
  height: 18px;
  width: 18px;
  
}
.fb-icon {background-image: url('../img/icons/facebook.png'); height: 24px; width: 24px;}
.twitter-icon {background-image: url('../img/icons/twitter.png'); height: 24px; width: 24px;}
.insta-icon {background-image: url('../img/icons/instagram.png'); height: 24px; width: 24px;}
.g-icon {background-image: url('../img/icons/g.png'); height: 24px; width: 24px;}
.bullet-icon{background-image: url('../img/icons/bullet-icon.png'); height: 18px; width: 18px;}

.student-icon{background-image: url('../img/icons/student-icon.png'); height: 64px; width: 64px;}
.school-icon{background-image: url('../img/icons/for-schools.svg'); height: 64px; width: 64px;}

.questionmark-icon{background-image: url('../img/icons/faq.svg'); height: 24px; width: 24px;}
.contactloc-icon{background-image: url('../img/icons/contact.svg'); height: 24px; width: 24px;}

.trasport-icon{background-image: url('../img/icons/transport.svg'); height: 24px; width: 24px;}
.elearning-icon{background-image: url('../img/icons/elearning.svg'); height: 24px; width: 24px;}
.security-icon{background-image: url('../img/icons/cctv.svg'); height: 24px; width: 24px;}

.schoollisting-icon{background-image: url('../img/icons/school-listing.png'); height: 24px; width: 24px;}
.onlineadmission-icon{background-image: url('../img/icons/online-admission.png'); height: 24px; width: 24px;}
.payfeeonline-icon{background-image: url('../img/icons/payfeeonline.png'); height: 24px; width: 24px;}
.gpsenabled-icon{background-image: url('../img/icons/gpsenabled-icon.svg'); height: 24px; width: 24px;}
.library-icon{background-image: url('../img/icons/library.svg'); height: 24px; width: 24px;}
.boarding-icon{background-image: url('../img/icons/boarding.svg'); height: 24px; width: 24px;}
.medicalcenter-icon{background-image: url('../img/icons/medicalcenter-icon.svg'); height: 24px; width: 24px;}
.smartclass-icon{background-image: url('../img/icons/smartclass-icon.svg'); height: 24px; width: 24px;}

.drama-icon{background-image: url('../img/icons/drama-icon.svg'); height: 24px; width: 24px;}
.cricket-icon{background-image: url('../img/icons/cricket-icon.svg'); height: 24px; width: 24px;}
.yoga-icon{background-image: url('../img/icons/yoga-icon.svg'); height: 24px; width: 24px;}
.swimming-icon{background-image: url('../img/icons/swimming-icon.svg'); height: 24px; width: 24px;}
.music-icon{background-image: url('../img/icons/music-icon.svg'); height: 24px; width: 24px;}
.soccer-icon{background-image: url('../img/icons/soccer-icon.svg'); height: 24px; width: 24px;}
.excursions-icon{background-image: url('../img/icons/excursions-icon.svg'); height: 24px; width: 24px;}
.tabletennis-icon{background-image: url('../img/icons/tabletennis-icon.svg'); height: 24px; width: 24px;}
.horseriding-icon{background-image: url('../img/icons/horseriding-icon.svg'); height: 24px; width: 24px;}
.basketball-icon{background-image: url('../img/icons/basketball-icon.svg'); height: 24px; width: 24px;}
.atheletics-icon{background-image: url('../img/icons/atheletics-icon.svg'); height: 24px; width: 24px;}

.filter-icon{background-image: url('../img/icons/filter-icon.png'); height: 18px; width: 18px;}
.info-icon{background-image: url('../img/icons/info.png'); height: 18px; width: 18px;}
.partner-icon{background-image: url('../img/icons/partner.png'); height: 32px; width: 32px;}
.addnew-icon{background-image: url('../img/icons/addnew-icon.png'); height: 18px; width: 18px;}
.save-icon{background-image: url('../img/icons/save-icon.png'); height: 18px; width: 18px;}
.delete-icon{background-image: url('../img/icons/delete-icon.png'); height: 18px; width: 18px;}
.check-icon{background-image: url('../img/icons/check-icon.png'); height: 24px; width: 24px;}
.location-icon{background-image: url('../img/icons/location-icon.png'); height: 18px; width: 18px;}
.cross-icon{background-image: url('../img/icons/cross-icon.png'); height: 18px; width: 18px;}

.arrowdown-icon{background-image: url('../img/icons/arrow-down.png'); height: 12px; width: 12px; background-position: 0px 2px;}
.arrowright-icon{background-image: url('../img/icons/right-arrow.png'); height: 12px; width: 12px;}


.success-icon{background-image: url('../img/icons/success-icon.svg'); height: 84px; width: 84px;}
.failed-icon{background-image: url('../img/icons/failed-icon.svg'); height: 84px; width: 84px;}

.edit-icon{background-image: url('../img/icons/edit-outline.svg'); height: 12px; width: 14px;}
.save-icon{background-image: url('../img/icons/save.svg'); height: 14px; width: 14px;}
.cancel-icon{background-image: url('../img/icons/cancel.svg'); height: 14px; width: 14px;}
.update-icon{background-image: url('../img/icons/updated.svg'); height: 14px; width: 14px;}
.delete-icon{background-image: url('../img/icons/Delete.svg'); height: 14px; width: 14px;}
.down-arrow-icon{background-image: url('../img/icons/downArrow-icon.svg'); height: 12px; width: 12px;}



