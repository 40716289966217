@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&family=Rubik:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '../scss/vars.scss';
@import '../scss/icons.scss';

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}
* {
    border: 0;
    padding: 0;
    margin: 0;
}
*, *::after, *::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
html, body {
    background:$white; 
    height: calc(100%); 
    font-size: 1rem; 
    line-height: inherit; 
    width: calc(100%); 
    font-family: $rubik-slab;
}
html, body, p, div, h1, h2, h3, h4, h5, h6, ul, li, ol, dl, img, pre, form, fieldset {
    margin: 0;
    padding: 0;
    font-family: $rubik-slab;
}
::-webkit-input-placeholder { /* Chrome */
    color: $primarycolor;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $primarycolor;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $primarycolor;
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: $primarycolor;
    opacity: 1;
  }


  ::-webkit-input-placeholder { /* Chrome */
    color: $primarycolor;
    transition: opacity 250ms ease-in-out;
  }
  :focus::-webkit-input-placeholder {
    opacity: 0.5;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $primarycolor;
    transition: opacity 250ms ease-in-out;
  }
  :focus:-ms-input-placeholder {
    opacity: 0.5;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $primarycolor;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  :focus::-moz-placeholder {
    opacity: 0.5;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: $primarycolor;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  :focus:-moz-placeholder {
    opacity: 0.5;
  }

a, a:visited, a:active {
    // color: unset;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    -webkit-transition: all 300ms linear 0ms;
      -khtml-transition: all 300ms linear 0ms;
      -moz-transition: all 300ms linear 0ms;
      -ms-transition: all 300ms linear 0ms;
      -o-transition: all 300ms linear 0ms;
      transition: all 300ms linear 0ms;
      // color: #c1282a;
}

input, textarea {
    line-height: inherit;
    font-family: inherit;
    font-size: inherit;
}
pre, code, kbd {
    font-size: inherit;
}

textarea:focus, input:focus, button:focus, select:focus {
    outline: none;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
label,
span,
p,
button,
ul,
li,
a {
    font-family: $rubik-slab;
}
i{}

.rotate {
    transform: rotate(-90deg);  
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
}
div#root {
  height: calc(100%); 
  background: $global-bg;
}
div.App, .main-container {height: calc(100%); }
div.noPadding {
    padding-left: 0;
    padding-right: 0;
}

div.noMargin {
    margin-left: 0;
    margin-right: 0;
}
*[topBar]:before {
  display: block;
  content: " ";
  margin-top: -75px;
  height: 75px;
  visibility: hidden;
}
.top-nav-wrapper{
  margin-bottom: 1.5rem;
  .logo-container {
    padding: 1.5rem 0 1.5rem 0;
  }
}

.footer-container {
  .fcont-wrapper {
    padding: 1.1rem 0;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.2rem;
      color: rgba(0, 0, 0, 0.8);
      text-decoration: none;
      -webkit-transition: all 300ms linear 0ms;
      -khtml-transition: all 300ms linear 0ms;
      -moz-transition: all 300ms linear 0ms;
      -ms-transition: all 300ms linear 0ms;
      -o-transition: all 300ms linear 0ms;
      transition: all 300ms linear 0ms;
      color: #c1282a;
      touch-action: manipulation;
      
    }
  }
}