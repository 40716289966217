@media screen and (max-width: 650px) {
  .inner-page-content .inner-page-right-container .button-wrap .comn {
    width: 100%;
  }
  .application-form-wrap .fld-row.disability-row .expanded-block .others-cell {
    margin: 16px 0px 0px;
}
  .modal.signin-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .otp-title-wrapp
    .otp-title {
    font-size: 24px;
  }
  .modal.confirmation-modal
    .modal-content
    .modal-body
    .table-wrapper
    .document-tbl {
    // border: 1px solid  #d9d9d9;
    display: grid;
    grid-template-columns:
      minmax(50px, 1fr) minmax(146px, 1fr) minmax(240px, 1fr)
      minmax(90px, 1fr) minmax(125px, 1fr);
    min-width: 100%;
    overflow-x: scroll;
    border-collapse: collapse;
    thead,
    tbody,
    tr {
      display: contents;
    }
    td,
    th {
      border-width: 1px 1px;
      display: flex;
      align-items: center;
    }
    td.doc-upload-fld {
      flex-direction: column;
      align-items: flex-start;
      input {
        width: 100%;
      }
    }
  }
  .application-form-wrap
    .currentschoolinfo-row
    .currentschoolinfo-cell
    label.lbl {
    margin-right: 0px;
  }
  .application-form-wrap .currentschoolinfo-row .currentschoolinfo-cell {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
  }
  .main-container .form-content-wrapper {
  }
  .main-container .content-area {
    padding-top: 80px;
    .content-area-inner {
      padding-left: 8px;
      padding-right: 8px;
      &.profile-page-wrap {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
  .header .cart-profile-wrap {
    justify-content: center;
    display: flex;
  }
  .main-container {
    .header.header {
      .common-header {
        display: none;
      }
      .mobile-header {
        top: 0;
        display: block;
        nav {
          height: 78px;
          background-color: rgba(255, 255, 255, 0.8);
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          box-shadow: 0 6px 6px rgba(0, 82, 224, 0.1);
          transition: all 0.2s linear;
          z-index: 1;
          position: relative;
        }
      }
    }
    .content-area {
      .content-area-inner {
        .hero-info-panel {
          flex-direction: column-reverse;
          column-gap: 24px;
          padding: 0;
          margin-top: 16px;
          .hero-item {
            &.left {
              h2 {
                font-size: 32px;
                line-height: 40px;
              }
              .btn-wrapper {
                .btn {
                  &.btn-primary {
                    margin: 0;
                    padding: 10px 18px;
                    font-size: 14px;
                    height: 48px;
                  }
                }
                a {
                  font-size: 14px;
                }
              }
            }
            &.right {
              img {
                width: 100%;
              }
            }
          }
        }
        .info-panel {
          padding: 8px 0;
          flex-direction: column;
          margin-bottom: 24px;
          h2 {
            white-space: normal;
            font-size: 14px;
            text-align: left;
            padding: 8px 0;
          }
        }
        .service-features {
          ul.service-type-tabs {
            li.nav-item {
              margin: 0;
              button.nav-link {
                font-size: 14px;
                padding: 12px 18px 12px 12px;
                span {
                  i {
                    height: 42px;
                    width: 42px;
                  }
                }
              }
            }
          }
          .tab-content {
            .tab-pane {
              .inner-tab-content {
                &.tab-content {
                  padding: 0;
                  .feature-card-main {
                    flex-direction: column;
                    row-gap: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .signin-model {
    &.modal {
      .modal-dialog {
        width: 100%;
        max-width: 100%;
        padding: 8px;
        margin: 0;
        .modal-content {
          .modal-header {
          }
          .model-body {
            flex-direction: column;
            margin: 0 16px 20px 16px;
            .model-body-col {
              &.left {
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                border-right: none;
                padding: 0 0 16px 0;
              }
            }
          }
        }
      }
    }
  }
  .signup-wrapper {
    grid-template-columns: auto;
    width: 100%;
    margin: 0 auto;
    padding: 16px 0;
    .signup-col {
      .infobox-pane {
        margin: 0;
        svg {
          height: auto;
        }
      }
      .infobox-inner {
        margin: 8px 0;
        padding: 0;
        h2 {
          font-size: 24px;
          line-height: 1.8rem;
          margin-bottom: 16px;
        }
        h6 {
          font-size: 16px;
          line-height: 1.4rem;
          margin-bottom: 16px;
        }
      }
      &.left {
        margin: 0;
        display: none;
      }
      &.right {
        .logo {
          display: flex;
          width: 100%;
          justify-content: center;
          svg {
            width: 60%;
          }
        }
        .form-wrapper {
          margin: 8px;
          .form-title {
            padding: 16px;
          }
          .form-container {
            padding: 0 16px 16px 16px;
            .form-wrap {
              .frm-row {
                flex-direction: column;
                .frm-cell {
                  &.pwd-cell {
                    .view-pwd-icon {
                      top: 32px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .modal.forgotpwd-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    h4 {
    padding: 8px 0;
    font-size: 18px;
  }
  .modal.forgotpwd-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    form {
    margin: 0 0 24px 0;
  }
  .application-form-wrap .fld-row .fld-cell .otp-block .item-cell.label {
    flex-direction: column;
    text-align: center;
  }
  .application-form-wrap .fld-row .fld-cell .otp-block .item-cell.label h2 {
    font-size: 20px;
    margin-bottom: 4px;
  }
  .application-form-wrap .fld-row .fld-cell .otp-block .item-cell.label h4 {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .application-form-wrap .fld-row .fld-cell .otp-block .item-cell {
    justify-content: center;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .profile-content
    .tab_btn_wrapper
    .tab-content
    .tab-pane
    .button-wrap {
    justify-content: flex-end;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .application-row
    .application-inner-wrap
    .app-timeline-stack
    .timeline-row
    .timeline-items
    .particulars-status
    .instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .application-row
    .application-inner-wrap
    .col-item
    .info-item
    .moreinfo-block {
    align-items: center;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .application-row
    .application-inner-wrap
    .app-timeline-control {
    justify-content: center;
  }
  .modal.calback-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    form {
    padding: 0;
    margin: 0;
  }
  .content-area .school-list-container .school-card .school-info-main {
  }
  .content-area
    .content-area-inner.internal-page-wrapper.school-details-wrap
    .inner-page-content.right {
    margin-bottom: 16px;
  }
  .modal.readytoapply-model .modal-dialog .modal-content .modal-header {
    padding: 16px 24px;
  }
  .modal.readytoapply-model .modal-dialog .modal-content .modal-body {
    margin: 0;
  }
  .content-area
    .content-area-inner.internal-page-wrapper.school-details-wrap
    .inner-page-content.right
    .sb-card-holder
    .school-list-container
    .school-card
    .school-info-main.card-body {
    display: flex;
    flex-direction: column;
  }
  // .modal.readytoapply-model
  //   .modal-dialog
  //   .modal-content
  //   .modal-body
  //   .readytoapply-block
  //   .applyoform-wrapper
  //   .frm-row
  //   .item-cell-wrap {
  //   display: flex;
  //   flex-direction: column;
  //   width: 100%;
  // }
  .modal.joinus-model
    .modal-dialog
    .modal-content
    .model-body
    .model-body-col
    form.application-form-wrap {
    padding: 0;
    margin: 0;
  }
  .modal.forgotpwd-model .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 16px;
    margin: 0;
  }
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper.hero-wrap
    .hero-caption {
    bottom: 0;
    padding: 16px 16px;
    border-radius: 16px;
    position: relative;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .profile-content
    .tab_btn_wrapper
    .tab-content {
    padding: 16px;
  }
  .modal-dialog
    .modal-body
    .model-body-col
    .form-container
    .otp-fld-outer
    .otp-fields-wrapper.otp-comp
    .otp-inner-fld {
    padding: 16px 0 0 0;
  }
  .modal-dialog
    .modal-body
    .model-body-col
    .form-container
    .otp-fld-outer
    .otp-fields-wrapper.otp-comp
    .otp-inner-fld
    div
    div
    input[type="tel"] {
    margin: 0;
  }
  .content-area .inner-page-container .content-section.bc-section {
  }
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper
    .img-wrap
    img {
    width: 100%;
  }
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper.hero-wrap
    .hero-caption
    h2 {
    font-size: 14px;
  }
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper {
    width: 100%;
    margin-bottom: 42px;
    flex-wrap: wrap;
    gap: 0;
  }
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper.full-width {
    width: 100%;
    flex-wrap: wrap;
  }
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper.infotoschools {
    width: 100%;
    flex-wrap: wrap;
  }
  .content-area
    .inner-page-container
    .content-section.faq-content-main
    .list-group {
    width: 100%;
    flex-wrap: wrap;
  }
  .content-area .inner-page-container .content-section.about-content-main {
    margin: 16px 0;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-bc-section {
    margin-bottom: 8px;
  }
  .content-area .contact-section-wrapper {
    width: 100%;
    padding: 0;
  }
  .content-area .content-area-inner.internal-page-wrapper {
    grid-template-columns: 1fr;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content.allschool-wrap.right {
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content.allschool-wrap.right
    .content-section {
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content.allschool-wrap.right
    .content-section
    .page-container {
    flex-direction: column;
  }

  .content-area .contact-section-wrapper .application-form-wrap .fld-row {
    display: flex;
    flex-direction: column;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content.left {
  }
  .content-area .school-list-container {
    grid-template-columns: 1fr;
  }
  .content-area .content-area-inner.internal-page-wrapper.school-details-wrap {
    grid-template-columns: 1fr;
  }
  .content-area
    .content-area-inner.internal-page-wrapper.school-details-wrap
    .inner-page-content.left
    .content-section
    .page-container {
    flex-direction: column;
  }
  .content-area
    .content-area-inner.internal-page-wrapper.school-details-wrap
    .inner-page-content.left
    .content-section
    .page-container
    .school-details-container
    .school-basic-info
    .cell.left {
    display: flex;
    justify-content: center;
  }
  .content-area
    .content-area-inner.internal-page-wrapper.school-details-wrap
    .inner-page-content.left
    .content-section
    .page-container
    .school-details-container
    .school-basic-info
    .cell.left
    .image-wrapper {
  }

  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .school-basic-info {
    grid-row-gap: 16px;
    display: flex;
    flex-direction: column;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .school-basic-info
    .cell
    ul.list-group {
    margin-left: 0;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main {
    grid-row-gap: 16px;
    display: flex;
    flex-direction: column;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.left {
    min-height: auto;
    border-radius: 16px;
    border: none;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .profile-content
    .header {
    flex-direction: column;
    row-gap: 8px;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content.left {
    border-radius: 0 0 16px 16px;
    border: none;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .application-row
    .application-inner-wrap {
    display: flex;
    flex-direction: column;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .col-item.right {
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }
  .manage-child-tbl-outer {
    overflow-x: auto;
  }
  .inner-page-content .inner-page-right-container {
    padding-left: 0;
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .cart-content-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .cart-content-row
    .cell.left.cart-content-area {
    padding-right: 0;
    border: none;
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .cart-content-row.nearby
    .school-list-container {
    flex-direction: column;
  }
  .modal.joinus-model .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 8px;
    margin: 0;
  }
  .modal.joinus-model
    .modal-dialog
    .modal-content
    .model-body
    .model-body-col
    form
    .fld-row {
    flex-direction: column;
  }
  .modal.joinus-model
    .modal-dialog
    .modal-content
    .model-body
    .model-body-col
    form
    .fld-row.button-wrap {
    row-gap: 16px;
  }
  .modal.add-child-model {
    margin: 0;
  }
  .modal.add-child-model .modal-dialog {
    padding: 0;
  }
  .modal.add-child-model .modal-dialog .modal-content .modal-body {
    margin: 0 8px 8px 8px;
  }
  .modal.add-child-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .model-frm
    .frm-cell {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .modal.add-child-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .model-frm
    .frm-cell
    label {
    margin-top: 16px;
    font-size: 14px;
  }
  .modal.add-child-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .model-frm
    .frm-cell
    .fld-inst {
    margin: 0;
  }
  .modal.add-child-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .model-frm
    .frm-cell
    .field-group-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .modal.add-child-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .model-frm
    .frm-cell
    .field-group-wrap
    .fld-wrap
    input[type="text"] {
    width: 100%;
  }
  .modal.add-child-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .model-frm
    .frm-cell
    .field-group-wrap
    .fld-wrap
    .form-select {
    width: 100%;
    font-size: 14px;
  }
  .modal.add-child-model.review-admission-modal
    .modal-dialog
    .modal-content
    .modal-body
    .accordion.accordion-flush
    .accordion-item
    .accordion-collapse.show
    .accordion-body
    .admin-detail-row {
    grid-template-columns: 1fr 1fr;
  }
  .modal.add-child-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .model-frm
    .frm-cell.button-wrap {
    padding: 16px 0;
  }
  .modal.add-child-model
    .modal-dialog
    .modal-content
    .modal-body
    .model-body-col
    .model-frm
    .frm-cell.button-wrap {
    justify-content: center;
  }
  .application-form-wrap .fld-row {
    flex-direction: column;
  }
  .application-form-wrap .fld-row.button-wrap {
    row-gap: 8px;
  }
  .content-area .content-area-inner .page-container {
    display: flex;
    flex-direction: row;
  }
  .content-area
    .content-area-inner.admmission-sequence-wrap
    .content-section
    .page-container {
    flex-direction: column;
    row-gap: 8px;
    padding: 0 8px;
  }
  .content-area
    .content-area-inner.inner-page-container.cart-page-wrapper
    .page-container {
    flex-direction: column;
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .cart-content-row
    .cell.right {
    padding: 0;
    border-radius: 0 0 8px 8px;
    margin: 0;
  }
  .content-area
    .content-area-inner
    .inner-page-content
    .content-section
    .page-container
    .row-wrapper
    .cell-row {
    display: flex;
    column-gap: 8px;
    flex-direction: row;
    align-items: center;
  }
  .modal.add-child-model.review-admission-modal
    .modal-dialog
    .modal-content
    .modal-body
    .accordion.accordion-flush
    .accordion-item
    .accordion-collapse.show
    .accordion-body
    .admin-detail-row:last-child {
    grid-template-columns: 1fr;
  }
  .modal.calback-model .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 16px;
    margin: 0;
  }
  .modal.alert-popup .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 16px;
    margin: 0;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .readytoapply-block
    .applyoform-wrapper
    .frm-row.form-header {
    display: none;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .readytoapply-block
    .applyoform-wrapper
    .frm-row.form-content {
    flex-direction: column;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .readytoapply-block
    .applyoform-wrapper
    .frm-row
    .cell
    label.applytoschool-lbl {
    display: block;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .readytoapply-block
    .applyoform-wrapper
    .frm-row.form-content
    .item-cell-wrap {
    flex-direction: column;
    row-gap: 8px;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .readytoapply-block
    .applyoform-wrapper
    .frm-row.form-content
    .item-cell-wrap
    .cell.app-fee-lbl {
    text-align: left;
    justify-content: flex-start;
  }
  // .modal.readytoapply-model
  //   .modal-dialog
  //   .modal-content
  //   .modal-body
  //   .readytoapply-block {
  //   display: flex;
  //   flex-direction: column;
  // }
  // .modal.readytoapply-model
  //   .modal-dialog
  //   .modal-content
  //   .modal-body
  //   .readytoapply-block
  //   .applyoform-wrapper
  //   .frm-row.form-header {
  //   display: none;
  // }
  // .modal.readytoapply-model
  //   .modal-dialog
  //   .modal-content
  //   .modal-body
  //   .readytoapply-block
  //   .applyoform-wrapper
  //   .frm-row.form-content
  //   .cell {
  //   width: 100%;
  // }
  // .modal.readytoapply-model
  //   .modal-dialog
  //   .modal-content
  //   .modal-body
  //   .readytoapply-block
  //   .applyoform-wrapper
  //   .frm-row
  //   .cell
  //   .form-select {
  //   width: 100%;
  // }
  // .modal.readytoapply-model
  //   .modal-dialog
  //   .modal-content
  //   .modal-body
  //   .readytoapply-block
  //   .applyoform-wrapper
  //   .frm-row.form-content
  //   .cell
  //   .applytoschool-lbl {
  //   display: block;
  //   white-space: nowrap;
  // }
  // .modal.readytoapply-model
  //   .modal-dialog
  //   .modal-content
  //   .modal-body
  //   .readytoapply-block
  //   .applyoform-wrapper
  //   .frm-row.form-content {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.left
    .sidebar-collapsible.accordion
    .admfrm-collapsible-panel.accordion-item
    h2.accordion-header {
    display: block;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .col-item
    .school-info-main
    .school-info-exerpts
    .moreinfo-block {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    overflow: hidden;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .col-item
    .school-info-main
    .school-info-exerpts
    .moreinfo-block
    .col.divider {
    display: none;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .profile-content
    .tab_btn_wrapper
    ul.nav-tabs
    li.nav-item
    button.nav-link {
    padding: 8px 8px;
    font-size: 14px;
  }
  .header .page-link-wrap {
    margin: 0;
  }
  .header #menu li.search {
    padding: 0;
    margin-bottom: 8px;
  }
  .header #menu li {
    padding: 6px 0;
  }
  .footer-main {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .footer-main .finner .fcol.btn-container .btn {
    font-size: 14px;
  }
  .footer-main .copyright-row {
    flex-direction: column;
    height: auto;
    padding: 8px 0;
    row-gap: 8px;
  }
  .footer-main .copyright-row .copyright-col {
    justify-content: center;
  }
  .content-area .contact-section-wrapper .application-form-wrap {
    padding: 0;
    border: none;
  }
  .content-area
    .content-area-inner.internal-page-wrapper.school-details-wrap
    .inner-page-content.left
    .content-section {
    padding: 16px 0px 24px;
  }
  .content-area .inner-page-container .content-section.faq-content-main {
    margin: 0;
  }
  .content-area
    .school-list-container
    .school-card.cart-block
    .card-body.school-info-main {
    display: flex;
    flex-direction: column;
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .row-wrapper.select-child-wrapper {
    padding: 16px;
  }
  .content-area
    .school-list-container
    .school-card.cart-active
    .school-info-main
    .info-item.school-info-exerpts
    .school-name {
  }
  .content-area .school-list-container .school-card .school-info-main {
    display: flex;
    flex-direction: column;
  }
  .footer-main .finner .fcol.btn-container {
    align-items: flex-start;
  }
  .footer-main .finner .fcol.btn-container .btn {
    width: 100%;
    text-align: center;
    box-sizing: inherit;
    height: auto;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .readytoapply-block {
    padding: 16px;
  }
  .content-area
    .school-list-container
    .school-card
    .school-info-main
    .info-item.school-info-exerpts
    .school-name
    .tutionfee-wrap {
    flex-direction: row;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .col-item
    .school-info-main {
    flex-direction: column;
    align-items: center;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.application-block
    .col-item
    .school-info-main
    .school-info-exerpts {
    align-items: center;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items.timeline-wrapper
    .timeline-list
    .timeline-info-panel
    .timeline-row {
    display: flex;
    flex-direction: row;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .readytoapply-block
    .applyoform-wrapper
    .frm-row
    .cell.button-wrap {
    justify-content: flex-start;
  }
  .modal.add-child-model.review-admission-modal
    .modal-dialog
    .modal-content
    .modal-body
    .accordion.accordion-flush
    .accordion-item
    .accordion-collapse.show
    .accordion-body
    .admin-detail-row {
    grid-template-columns: 1fr;
  }
  .modal.add-child-model.review-admission-modal
    .modal-dialog
    .modal-content
    .modal-body
    .accordion.accordion-flush
    .accordion-item
    .accordion-collapse.show
    .accordion-body
    .admin-detail-row
    .admin-detail-cell {
    flex-direction: row;
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .cart-content-row
    .cell.right
    .cart-payment-card
    .school-info-main
    .info-list-group
    .list-group-item {
    padding: 8px;
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .cart-content-row
    .cell.right
    .cart-payment-card
    .school-info-main
    .info-list-group
    .list-group-item:first-child {
    padding: 8px;
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .cart-content-row
    .cell.right
    .cart-payment-card
    .card-header-item.title {
    padding: 8px;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .academic-stat-wrapper
    .academic-stats-content
    .academic-stats-row {
    flex-direction: column;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .school-details-container
    .academic-stat-wrapper
    .academic-stats-content
    .academic-stats-row
    .cell
    .particular.adm-status {
    flex-direction: column;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .titlebar {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content
    .content-section
    .page-container
    .titlebar
    .cell.right {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .application-form-wrap .fld-row.expand-block .expand-block-cell {
    flex-direction: column;
    align-items: flex-start;
  }
  .admmission-sequence-wrap .inner-page-content .inner-page-right-container {
    padding: 16px 0 0 0;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.right.content
    .row-items
    .col-item {
    justify-content: center;
  }
  .header #menu {
    padding-left: 16px;
    padding-right: 16px;
  }
  .main-container.admin-contianer
    .content-area
    .content-area-inner.dashboard-wrapper {
    padding: 8px;
  }
  .content-area .content-area-inner .metrics-wrap {
    grid-template-columns: 1fr;
    row-gap: 16px;
    overflow-x: auto;
  }
  .content-area .content-area-inner .chart-wrap {
    grid-template-columns: 1fr;
    row-gap: 16px;
    overflow-x: auto;
  }
  .main-container .mainmenu-wrap {
    padding: 0;
  }
  .main-container .title-kpi-wrapper {
    height: auto;
    padding: 0;
    flex-direction: column;
  }
  // .main-container .mainmenu-wrap button.navbar-toggler{display: block;}
  .main-container .mainmenu-wrap .menu-items {
    flex-direction: column;
    height: auto;
  }
  .main-container .mainmenu-wrap .navbar-collapse {
    flex-direction: column;
  }
  .main-container .mainmenu-wrap .navbar-collapse.collapse.show .menu-items {
    flex-direction: column;
  }
  .main-container .title-kpi-wrapper .kpi-wrapper {
    height: auto;
  }
  .main-container .title-kpi-wrapper .kpi-wrapper .kpi-list-group {
    flex-direction: column;
  }
  .main-container
    .title-kpi-wrapper
    .kpi-wrapper
    .kpi-list-group
    .list-group-item.application-status {
    flex-direction: column;
  }
  .footer-main .finner .fcol.quicklinks ul.list-group {
    flex-direction: row;
    column-gap: 16px;
    flex-wrap: wrap;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content.left
    .school-nearby-collapsible
    .admfrm-collapsible-panel.accordion-item
    h2.accordion-header {
    display: block;
  }
  .modal.add-child-model.review-admission-modal
    .modal-dialog
    .modal-content
    .modal-body
    .accordion.accordion-flush
    .accordion-item
    .accordion-collapse.show
    .accordion-body
    .tab-wrapper
    .tab-content {
    padding: 8px;
  }
  .search-inner .wrapper .sc-gswNZR.iLjAXM {
    height: 200px;
    overflow-x: auto;
  }
  .payment-history-tbl {
    overflow-y: auto;
  }
  .payeedetails-model
    .modal-dialog
    .modal-content
    .modal-body
    .order-wrapper
    ul.order-list
    .list-items-wrap {
    flex-direction: column;
  }
  .payeedetails-model
    .modal-dialog
    .modal-content
    .modal-body
    .order-wrapper
    ul.order-list
    .list-items-wrap
    li
    .order-item.title {
    margin-bottom: 0;
  }
  .payeedetails-model
    .modal-dialog
    .modal-content
    .modal-body
    .order-wrapper
    ul.order-list
    .list-items-wrap
    li {
    margin-bottom: 16px;
  }
  .signup-wrapper.verify-phone-main .signup-col svg.verifyph-logo {
    width: 70%;
  }
  .signup-wrapper.verify-phone-main .signup-col .form-wrapper.verify-phone {
    width: auto;
    margin: 24px;
  }
  .signup-wrapper.verify-phone-main
    .signup-col
    .form-wrapper.verify-phone
    .form-title {
    padding: 16px;
  }
  .signup-wrapper .signup-col .form-wrapper.verify-phone .form-container {
    padding: 0 16px 16px 16px;
  }
  .content-area
    .internal-page-wrapper
    .inner-content-wrap.lateFee-wrapp
    .amount-main-wrapp
    .amount-inner-wrapp {
    flex-direction: column;
    padding-left: 0px;
    .form-select-sm,
    .form-control-sm {
      margin: 0px !important;
      width: 100% !important;
    }
  }
  .content-area
    .internal-page-wrapper
    .inner-content-wrap.lateFee-wrapp
    .amount-main-wrapp
    .amount-inner-wrapp.submission-date-wrapp
    .date-main-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 10px;
    column-gap: 14px;
    width: auto;
  }
  .content-area
    .internal-page-wrapper
    .inner-content-wrap.lateFee-wrapp
    .amount-main-wrapp
    .date-selection-wrapp {
    margin: 10px 10px 28px 0px;
    width: auto;
  }
  .content-area
    .internal-page-wrapper
    .inner-content-wrap.lateFee-wrapp
    .border-wrapp {
    width: 100% !important;
  }
}
@media (max-width: 991px) {
  .signin-model.modal .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 8px;
    margin: 0;
}
  .modal.readytoapply-model
  .modal-dialog
  .modal-content
  .modal-body
  .readytoapply-block
  .applyoform-wrapper
  .frm-row.form-header {
  display: none;
}
.modal.readytoapply-model .modal-dialog .modal-content .modal-body .readytoapply-block {
  display: flex;
  flex-direction: column;
  padding: 0px 16px 30px;
}

.modal.readytoapply-model .modal-dialog .modal-content .modal-body .readytoapply-block .applyoform-wrapper .frm-row.form-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.modal.readytoapply-model .modal-dialog .modal-content .modal-body .readytoapply-block .applyoform-wrapper .frm-row .item-cell-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modal.readytoapply-model .modal-dialog .modal-content .modal-body .readytoapply-block .applyoform-wrapper .frm-row.form-content .cell {
  width: 100%; margin-bottom: 16px;
}
.modal.readytoapply-model .modal-dialog .modal-content .modal-body .readytoapply-block .applyoform-wrapper .frm-row.form-content .cell .applytoschool-lbl {
  display: block;
  white-space: nowrap;
}
.modal.readytoapply-model .modal-dialog .modal-content .modal-body .readytoapply-block .applyoform-wrapper .frm-row .cell .form-select {
  width: 100%;
}   
.modal.readytoapply-model .modal-dialog .modal-content .modal-body .readytoapply-block .applyoform-wrapper .frm-row.form-content .cell.app-fee-lbl{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}   
  




  .modal.confirmation-modal
    .modal-content
    .modal-body
    .table-wrapper
    .document-tbl
    th.doc-upload-btn {
    justify-content: center;
    text-align: center;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .profile-content
    .table-wrapper-outer {
    // overflow: hidden;
    .table {
      tbody,
      thead,
      tr {
        display: contents;
      }
      display: grid;
      grid-template-columns:
        minmax(20px, 1fr) minmax(80px, 1fr) minmax(143px, 1fr) minmax(
          115px,
          1fr
        )
        minmax(110px, 1fr) minmax(100px, 1fr)
        minmax(120px, 1fr) minmax(280px, 1fr);
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
  .payment-history-tbl {
    overflow-y: auto;
  }
  .content-area .internal-page-wrapper .inner-content-wrap .table-wrapper {
    overflow-x: scroll;
  }
  .content-area .internal-page-wrapper.two-columns {
    flex-direction: column;
  }
  .content-area
    .internal-page-wrapper
    .inner-content-wrap
    .table-wrapper.manage-fee-wrapp
    table
    tbody
    tr
    td {
    padding: 16px;
  }
  .content-area .internal-page-wrapper .filterpanel.sidenav {
    min-height: auto;
  }
}
@media (max-width: 375px) {
  .application-form-wrap .fld-row .fld-cell .otp-block {
    padding: 36px 26px;
  }
  .application-form-wrap
    .fld-row
    .fld-cell
    .otp-block
    .item-cell.otpinput-box
    input {
    width: 43px !important;
    height: 43px;
  }
}
@media (max-width: 320px) {
  .application-form-wrap .fld-row .fld-cell label.lbl.pr-n5 {
    padding-right: 5px;
}
  .application-form-wrap .fld-row .fld-cell .otp-block {
    padding: 18px 14px;
  }
  .application-form-wrap
    .fld-row
    .fld-cell
    .otp-block
    .item-cell.otpinput-box
    div {
    column-gap: 12px;
  }
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .modal.alert-popup .modal-dialog {
    width: 400px;
    max-width: 400px;
  }
  // .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .application-row .application-inner-wrap{display: flex; flex-direction: column;}
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .profile-content
    .header {
    flex-direction: row;
  }
  // .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items .col-item {justify-content: flex-start;}
  // .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .col-item .school-info-main .school-info-exerpts {align-items: flex-start;}
  // .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .col-item.right{flex-direction: row; align-items: center; column-gap: 16px;}
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper {
  }
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper.full-width {
  }
  .content-area
    .inner-page-container
    .content-section.about-content-main
    .section-wrapper.infotoschools {
  }
  .content-area
    .inner-page-container
    .content-section.faq-content-main
    .list-group {
  }
  .header #menu li {
    padding: 12px 18px;
  }
  // .content-area .content-area-inner .metrics-wrap{ display: flex; flex-direction: column; row-gap: 16px;}
  // .content-area .content-area-inner .chart-wrap{ overflow-x: auto; display: flex; flex-direction: column; row-gap: 16px;}
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .modal.alert-popup .modal-dialog {
    width: 400px;
    max-width: 400px;
  }
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .tbl-grid-wrapper table td input {
    font-size: 13px;
  }
  .form-select-sm {
    font-size: 13px;
  }
  table tbody tr td,
  table thead tr th {
    font-size: 13px;
    text-align: center;
  }
  .tbl-grid-wrapper table th {
    font-size: 13px;
  }
  .tbl-grid-wrapper table td,
  .tbl-grid-wrapper table th {
    padding: 15px 7px;
  }

  .tbl-grid-wrapper table {
    border-collapse: collapse;
    display: grid;
    grid-template-columns:
      minmax(80px, 1fr) minmax(80px, 1fr) minmax(120px, 1fr)
      minmax(85px, 1fr) minmax(85px, 1fr) minmax(195px, 3.5fr) minmax(
        195px,
        3.5fr
      )
      minmax(195px, 3.5fr) minmax(81px, 1fr) minmax(90px, 1fr) minmax(109px, 1fr);
    min-width: 100%;
  }
}

@media (max-width: 1281px) {
  /* hi-res laptops and desktops */
  .tbl-grid-wrapper table {
    overflow-x: scroll;
    grid-template-columns:
      minmax(100px, 1fr) minmax(90px, 1fr) minmax(120px, 1fr)
      minmax(100px, 1fr) minmax(100px, 1fr) minmax(225px, 3.5fr) minmax(
        225px,
        3.5fr
      )
      minmax(225px, 3.5fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(120px, 1fr);
  }
  .payment-history-tbl table.table tbody tr td.download-invoice {
    text-align: center;
  }
}

@media screen and (max-width: 1199px) {
  .main-container .form-content-wrapper {
  }

  .content-area .content-area-inner .chart-wrap {
    grid-template-columns: 2.06fr 1fr;
  }
  .main-container .content-area {
    padding-top: 78px;
  }
  .main-container {
    .header.header {
      .common-header {
        display: none;
      }
      .mobile-header {
        top: 0;
        display: block;
        nav {
          height: 66px;
          background-color: rgba(255, 255, 255, 0.8);
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          box-shadow: 0 6px 6px rgba(0, 82, 224, 0.1);
          transition: all 0.2s linear;
          z-index: 1;
          position: relative;
          .mob-logo-wrap {
            position: absolute;
            top: 18px;
            left: 84px;
            z-index: 99;
          }
        }
      }
    }
    .content-area {
      .content-area-inner {
        .hero-info-panel {
          .hero-item {
            &.left {
              h2 {
                font-size: 32px;
                line-height: 40px;
              }
              .btn-wrapper {
                .btn {
                  &.btn-primary {
                    margin: 0;
                    padding: 10px 18px;
                    font-size: 14px;
                    height: 48px;
                  }
                }
                a {
                  font-size: 14px;
                }
              }
            }
            &.right {
              img {
                width: auto;
                max-width: 100%;
              }
            }
          }
        }
        .info-panel {
          padding: 8px 0;
          flex-direction: column;
          margin-bottom: 24px;
          h2 {
            white-space: normal;
            font-size: 14px;
            text-align: left;
            padding: 8px 0;
          }
        }
        .service-features {
          ul.service-type-tabs {
            li.nav-item {
              margin: 0;
              button.nav-link {
                font-size: 14px;
                padding: 12px 18px 12px 12px;
                span {
                  i {
                    height: 42px;
                    width: 42px;
                  }
                }
              }
            }
          }
          .tab-content {
            .tab-pane {
              .inner-tab-content {
                &.tab-content {
                  padding: 0;
                  .feature-card-main {
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .signup-wrapper {
    grid-template-columns: auto;
    width: 100%;
    margin: 0 auto;
    padding: 16px 0;
    .signup-col {
      .infobox-pane {
        margin: 0;
        svg {
          height: auto;
        }
      }
      .infobox-inner {
        margin: 8px 0;
        padding: 0;
        h2 {
          font-size: 24px;
          line-height: 1.8rem;
          margin-bottom: 16px;
        }
        h6 {
          font-size: 16px;
          line-height: 1.4rem;
          margin-bottom: 16px;
        }
      }
      &.left {
        margin: 0;
        display: none;
      }
      &.right {
        .logo {
          display: flex;
          width: 100%;
          justify-content: center;
          svg {
            width: 60%;
          }
        }
      }
    }
  }
  .content-area .content-area-inner.internal-page-wrapper.school-details-wrap {
    grid-template-columns: 1fr;
  }
  .footer-main .copyright-row .copyright-col {
    padding: 0;
  }
  .footer-main .finner .fcol:first-child {
    display: none;
  }
  .content-area .content-area-inner.internal-page-wrapper {
    grid-template-columns: 1fr;
    padding: 0 8px;
  }
  // .main-container .content-area .content-area-inner{padding-left: 8px; padding-right: 8px;}
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content.inner-page-content.allschool-wrap.right {
  }
  .modal.add-child-model.review-admission-modal {
    padding-left: 0 !important;
  }
  .modal.add-child-model.review-admission-modal .modal-dialog {
    min-width: 100%;
  }
  .content-area
    .school-list-container
    .school-card.cart-block
    .card-body.school-info-main {
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .row-wrapper.select-child-wrapper {
    padding: 16px;
  }
  .content-area
    .school-list-container
    .school-card.cart-active
    .school-info-main
    .info-item.school-info-exerpts
    .school-name {
  }
  .content-area .school-list-container .school-card .school-info-main {
  }
  .content-area
    .content-area-inner.cart-page-wrapper
    .page-container
    .cart-content-row {
    display: flex;
    flex-direction: column;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main {
    grid-row-gap: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }
  .content-area
    .content-area-inner.profile-page-wrap
    .inner-page-content
    .content-section.profile-content-main
    .col.left {
    min-height: auto;
    border-radius: 16px;
    border: none;
  }
  .content-area
    .content-area-inner.internal-page-wrapper
    .inner-page-content.left {
    border-radius: 0 0 16px 16px;
    border: none;
  }

  .content-area
    .content-area-inner.admmission-sequence-wrap
    .content-section
    .bc-main-wrap {
    padding-right: 16px;
    padding-left: 16px;
  }

  .content-area
    .content-area-inner.admmission-sequence-wrap
    .content-section
    .content-area-inner.internal-page-wrapper
    .inner-page-content.left.sidebar {
    padding-top: 0;
  }
  .content-area
    .content-area-inner.admmission-sequence-wrap
    .content-section
    .content-area-inner.internal-page-wrapper
    .school-nearby-collapsible {
    padding-top: 0;
  }
  .content-area
    .content-area-inner.admmission-sequence-wrap
    .content-section
    .content-area-inner.internal-page-wrapper
    .school-nearby-collapsible
    .admfrm-collapsible-panel
    .accordion-collapse.collapse.show {
    margin-top: 16px;
  }
  .content-area
    .content-area-inner.admmission-sequence-wrap
    .content-section
    .content-area-inner.internal-page-wrapper
    .school-nearby-collapsible
    .admfrm-collapsible-panel
    h2.accordion-header {
    margin-bottom: 0;
  }
  .content-area
    .content-area-inner.admmission-sequence-wrap
    .content-section
    .content-area-inner.internal-page-wrapper
    .school-nearby-collapsible
    .admfrm-collapsible-panel
    h2.accordion-header
    button.accordion-button {
    padding-left: 0;
    padding-right: 0;
  }
  .admmission-sequence-wrap .inner-page-content .inner-page-right-container {
    padding: 16px 0 0 0;
  }
  .content-area-inner.admmission-sequence-wrap.container {
    padding: 16px;
  }
}

@media (max-width: 1440px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 380px) and (max-width: 390px) {
  .admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane {
    width: 370px;
    overflow: hidden;
    overflow-x: scroll;
}
}
@media (min-width: 391px) and (max-width: 414px) {
  .admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane {
    width: 390px;
    overflow: hidden;
    overflow-x: scroll;
}
}
