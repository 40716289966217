// icons
.icons {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}

.pymtReceived-icon{
  background-image: url('../img/icons/payment-received.svg'); 
  height: 85px; 
  width: 96px;
}

.email-icon {
  background-image: url('../img/icons/email.png');
  height: 20px;
  width: 20px;
}
.phone-icon {
  background-image: url('../img/icons/phone.png');
  height: 20px;
  width: 20px;
}
.divide-dot {
  background-image: url('../img/icons/divide-dot.png');
  height: 18px;
  width: 18px;
  
}
.fb-icon {background-image: url('../img/icons/facebook.png'); height: 24px; width: 24px;}
.print-icon {background-image: url('../img/icons/printer.png'); height: 24px; width: 24px;}
.joinus-icon {background-image: url('../img/icons/joinus-icon.svg'); height: 24px; width: 24px;}
.twitter-icon {background-image: url('../img/icons/twitter.png'); height: 24px; width: 24px;}
.insta-icon {background-image: url('../img/icons/instagram.png'); height: 24px; width: 24px;}
.g-icon {background-image: url('../img/icons/g.png'); height: 24px; width: 24px;}
.bullet-icon{background-image: url('../img/icons/bullet-icon.png'); height: 18px; width: 18px;}
.filter-icon{background-image: url('../img/icons/filter-icon.png'); height: 18px; width: 18px;}
.info-icon{background-image: url('../img/icons/info.png'); height: 18px; width: 18px;}

.fbLike-icon {background-image: url('../img/icons/facebook.svg'); height: 20px !important; width: 20px !important;}
.twtUs-icon {background-image: url('../img/icons/twitter.svg'); height: 24px ; width: 24px;}


.student-icon{background-image: url('../img/icons/for-schools.png'); height: 64px; width: 64px;}
.school-icon{background-image: url('../img/icons/student-icon.png'); height: 64px; width: 64px;}


.questionmark-icon{background-image: url('../img/icons/question-mark.png'); height: 24px; width: 24px;}
.contactloc-icon{background-image: url('../img/icons/contact-loc.png'); height: 24px; width: 24px;}
.schoollisting-icon{background-image: url('../img/icons/school-listing.png'); height: 24px; width: 24px;}
.onlineadmission-icon{background-image: url('../img/icons/online-admission.png'); height: 24px; width: 24px;}
.payfeeonline-icon{background-image: url('../img/icons/payfeeonline.png'); height: 24px; width: 24px;}
.partner-icon{background-image: url('../img/icons/partner.png'); height: 32px; width: 32px;}
.security-icon{background-image: url('../img/icons/cctv.svg'); height: 24px; width: 24px;}
.trasport-icon{background-image: url('../img/icons/transport.svg'); height: 24px; width: 24px;}
.watchman-icon{background-image: url('../img/icons/watchman-icon.svg'); height: 24px; width: 24px;}
.elearning-icon{background-image: url('../img/icons/elearning.svg'); height: 24px; width: 24px;}
.library-icon{background-image: url('../img/icons/library.svg'); height: 24px; width: 24px;}
.auditorium-icon{background-image: url('../img/icons/auditorium.svg'); height: 24px; width: 24px;}
.biometrics-icon{background-image: url('../img/icons/biometrics-icon.svg'); height: 24px; width: 24px;}
.boarding-icon{background-image: url('../img/icons/boarding.svg'); height: 24px; width: 24px;}
.gpsenabled-icon{background-image: url('../img/icons/gpsenabled-icon.svg'); height: 24px; width: 24px;}
.atheletics-icon{background-image: url('../img/icons/atheletics-icon.svg'); height: 24px; width: 24px;}
.basketball-icon{background-image: url('../img/icons/basketball-icon.svg'); height: 24px; width: 24px;}
.air-conditioner{background-image: url('../img/icons/air-conditioner.svg'); height: 24px; width: 24px;}
.wifi-icon{background-image: url('../img/icons/wifi-icon.svg'); height: 24px; width: 24px;}
.sciencelab-icon{background-image: url('../img/icons/sciencelab-icon.svg'); height: 24px; width: 24px;}
.disabledfriendly-icon{background-image: url('../img/icons/disabledfriendly-icon.png'); height: 24px; width: 24px;}
.canteen-icon{background-image: url('../img/icons/canteen-icon.svg'); height: 24px; width: 24px;}
.medicalcenter-icon{background-image: url('../img/icons/medicalcenter-icon.svg'); height: 24px; width: 24px;}
.digitallearning-icon{background-image: url('../img/icons/digitallearning-icon.svg'); height: 24px; width: 24px;}
.daycare-icon{background-image: url('../img/icons/daycare-icon.svg'); height: 24px; width: 24px;}
.artandcraft-icon{background-image: url('../img/icons/artandcraft-icon.svg'); height: 24px; width: 24px;}
.playgroup-icon{background-image: url('../img/icons/playgroup-icon.svg'); height: 24px; width: 24px;}
.dayboarding-icon{background-image: url('../img/icons/dayboarding-icon.svg'); height: 24px; width: 24px;}
.fullboarding-icon{background-image: url('../img/icons/fullboarding-icon.svg'); height: 24px; width: 24px;}
.roboticslab-icon{background-image: url('../img/icons/robotics.svg'); height: 24px; width: 24px;}
.smartclass-icon{background-image: url('../img/icons/smartclass-icon.svg'); height: 24px; width: 24px;}
.kidsmeal-icon{background-image: url('../img/icons/kidsmeal-icon.svg'); height: 24px; width: 24px;}
.aftercare-icon{background-image: url('../img/icons/aftercare-icon.svg'); height: 24px; width: 24px;}

.computer-lab{background-image: url('../img/icons/computer-lab.svg'); height: 24px; width: 24px;}


.green-arrow-icon{background-image: url('../img/icons/green-arrow-icon.png'); height: 11px; width: 20px;}

.qmark-icon{background-image: url('../img/icons/guide-icon.svg'); height: 24px; width: 24px;}
.guide-icon{background-image: url('../img/icons/guide-icon.svg'); height: 24px; width: 24px;}

.addnew-icon{background-image: url('../img/icons/addnew-icon.png'); height: 18px; width: 18px;}
.save-icon{background-image: url('../img/icons/save-icon.png'); height: 18px; width: 18px;}
.delete-icon{background-image: url('../img/icons/delete-icon.png'); height: 18px; width: 18px;}
.check-icon{background-image: url('../img/icons/check-icon.png'); height: 24px; width: 24px;}
.location-icon{background-image: url('../img/icons/location-icon.png'); height: 18px; width: 18px;}
.cross-icon{background-image: url('../img/icons/cross-icon.png'); height: 18px; width: 18px;}

.arrowdown-icon{background-image: url('../img/icons/arrow-down.png'); height: 12px; width: 12px;}
.arrowright-icon{background-image: url('../img/icons/right-arrow.png'); height: 12px; width: 12px;}
.link-icon{background-image: url('../img/icons/icon-attachment.svg'); height: 16px; width: 16px;}
.link-white-icon{background-image: url('../img/icons/link-white.svg'); height: 16px; width: 16px;}

.dashboard-icon{background-image: url('../img/icons/dashboard.svg'); height: 18px; width: 18px;}
.manageprofile-icon{background-image: url('../img/icons/manage-profile.svg'); height: 18px; width: 18px;}
.oneapplicationform-icon{background-image: url('../img/icons/admission-form.svg'); height: 18px; width: 18px;}
.managefee-icon{background-image: url('../img/icons/manage-fee.svg'); height: 18px; width: 18px;}
.managechild-icon{background-image: url('../img/icons/manage-child.svg'); height: 18px; width: 18px;}
.paymenthistory-icon{background-image: url('../img/icons/payment-history.svg'); height: 18px; width: 18px;}
.calender-icon{background-image: url('../img/icons/Calendar.svg'); height: 16px !important; width: 16px !important;}
.pymtFfailed-icon{background-image: url('../img/icons/pymt-fail.svg'); height: 85px; width: 96px;}
.pymtReceived-icon{background-image: url('../img/icons/payment-received.svg'); height: 85px; width: 96px;}
.email-pytm {
  background-image: url('../img/icons/email-pytm.svg');
  height: 20px;
  width: 20px;
}
.phone-pytm {
  background-image: url('../img/icons/phone-pytm.svg');
  height: 20px;
  width: 20px;
}