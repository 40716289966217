/*Header*/
.header{
  .region-dropdown-wrap{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    i.loc-icon{
      width: 18px;
      height: 18px;
      margin-right: 4px;
      svg{
        vertical-align: baseline;
      }
    }
    .dropdown{
      &.show{}
      .dropdown-menu{
        &.show{
          ul.list-unstyled{
            height: 252px;
            overflow: auto;
            a{

            }
          }
        }
      }
        a{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color:$primarycolor;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: flex-start;
            position: relative;
            .location-lbl{
                margin-right: 8px;
            }
            i.icons{}
         }
    }
  }
    .top-navigation{
        background: $primarycolor;
        .container{}
        .siteNav{
          a{
            color: $white;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: right;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.4);
            white-space: nowrap;
            &:hover{
              color: $white;
            }
            &.active-page{
              color: $white;
            }
          }
          .dot-wrap{
            text-align: center; 
            padding: 6px 0;
            i.icons{margin: 0 4px;}
          }
        }
        .contact-info{
          .navbar-text{
            a{
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              display: flex;
              align-items: center;
              text-align: right;
              letter-spacing: 0.01em;
              color: rgba(255, 255, 255, 0.8);
              white-space: nowrap;
              i.icons{
                margin-right: 8px;
              }
            }
          }
          .dot-wrap{
            text-align: center; 
            i.icons{margin: 0 8px;}
          }
          .social-links{
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: space-evenly;
            margin-right: 0;
            a{
              padding: 0 8px;
              &:last-child{padding-right: 0;}
            }
          }
        }
      
      }
      
.navbar-header{
    background: $white;
    min-height: 68px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    width: 100%;
    // box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 6px 6px rgba(0, 82, 224, 0.1);
    transition: all 0.2s linear;
    z-index: 1;
    position: relative;
    .header-container{
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      grid-column-gap: .5rem;
      align-items: center;
      align-content: center;
      // padding: 0;
      .header-item{
        display: flex;
        flex-direction: row;
        &.brand-logo{
          justify-content:flex-start;
          img{width: auto; height: 32px;}
        }
        &.search-region-pane{
          justify-content:center;
          
          .search-wrapper{
            margin-left: 24px;
            .srch-form-wrap{
              position: relative;
              input.search-field{
                width: 480px; 
                border-radius: 30px;
                padding: 6px 16px 6px 42px;
              }
              button.search-btn{
                background: none; 
                border: none; 
                padding: 0; 
                position: absolute; 
                top: 4px;
                left: 16px;}
            }
          }
        }
        
  
      }
    }
  
  }  
  .cart-profile-wrap{
    justify-content: flex-end;
    
    .cart-num-comp{
      position: relative;
      border-right: 1px solid #c5c5c5;
      margin-right: 16px;
      padding-right: 16px;
      a{
        background: none;
        border: none;
        padding: 0;
        &.btn-success{
        }
        .cart-img{padding: 0 8px 0 0;}
        .num-badge{
          position: absolute;
          top: -10px;
          right: 12px;
          background: #FF5C6C;
          color: #ffffff;
          border-radius: 24px;
          width: 24px;
          height: 24px;
          align-content: center;
          align-items: center;
          justify-content: center;
          display: flex;
          font-size: 14px;
        }
      }
      
    }
    .guide-link{
      border-right: 1px solid #c5c5c5;
      margin-right: 16px;
      padding-right: 16px;
      a{

      }

    }
    .user-profile{
      .dropdown{
        a{
          font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
            color: rgba(0, 0, 0, 0.8);
          .user-name{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            text-align: right;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        &.show{
          .dropdown-menu{
            border-radius: 4px;
            padding: 0;
            &.show{
              ul.list-unstyled{
                margin-bottom: 0;
                text-align: left;
               
                a{
                  text-align: left;
                  padding:16px 16px;
                  &:active{
                    background: $primarycolor;
                    color: $white;
                    &:first-child{
                      border-radius: 4px 4px 0 0;
                    }
                    &:last-child{
                      border-radius: 0 0 4px 4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .profile-login{
      div{font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
      }
    }
    .user-profile{

    }
  }
  #menuToggle {
    display: block;
    position: relative;
    top: 24px;
    left: 32px; 
    z-index: 1;  
    -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
  }
  
  #menuToggle a {
    text-decoration: none;
    color: #232323;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }
  
  #menuToggle a:hover {
    color: #4f7794;
  }
  
  
  /* The hamburger button hidden inside an input element */
  #menuToggle input[type=checkbox] {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -7px;
    left: -5px;  
    padding: 0;
    cursor: pointer;  
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */  
    -webkit-touch-callout: none;
  }
  
  #menuToggle label.toggle-lbl {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
  }
  #menuToggle label.toggle-lbl span {
    display: none;
  }
  
  /*
   * Hamburger
   */
  #menuToggle label.toggle-lbl div {
    display: block;
    width: 30px;
    height: 2px;
    margin-bottom: 6px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;  
    z-index: 1;  
    -webkit-transform-origin: 4px 0px;  
        -ms-transform-origin: 4px 0px;  
            transform-origin: 4px 0px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  #menuToggle label.toggle-lbl div:first-child {
    -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  
  #menuToggle label.toggle-lbl div:nth-child(2) {
      -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  
  /* 
   * Change the color of the hamburger when toggled
   */
  #menuToggle input:checked + label.toggle-lbl div {
    background: #232323;
  }
  
  /*
   * Use absolute positioning to place the menu off-screen
   */
  #menu {
    position: absolute;
    width: 100%;
    height: calc(100vh + 100px);
    margin: -100px 0 0 -32px;
    padding: 50px;
    padding-top: 125px;
    background: rgba(255, 255, 255, 0.98);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(2px);
    box-shadow: 0 6px 6px rgb(0 82 224 / 10%);
    transition: all 0.2s linear;
    
    list-style-type: none;
    /* Hide the menu off-screen to the left */
    left: -100%;
    -webkit-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    transition: left 0.3s ease; 
  }
  
  #menu li {
    padding: 10px 0;
    font-size: 14px;
   
    &.search{
      position: relative;
      z-index: 2;
    }
    &.signin{
      border: 1px solid #efefef;
      text-align: center;
      border-radius: 24px;
      margin: 8px 0;
      padding: 8px 12px;
      background: #fff;
      line-height: 24px;
      .profile-login{
        div{
          color: $primarycolor;
          font-weight: 700;
          white-space: nowrap;
          cursor: pointer;
        }
      }
      
    }
    &.locations{
      a{
        font-size: 18px;
      }
    }
    
    &.page-link{}
    &.contact-info{}
  }
  .loc-cartinfo-nav-wrapper{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    li{
      // flex-grow: 1;
      flex-basis: 0;
      &.locations{

      }
      &.signin{
        .header-item{
          display: flex;
          justify-content: center;
          .cart-num-comp{
            a{
              .cart-img{
                padding: 0 24px 0 0;
              }
            }
          }
        }
      }

    }
  }
  .page-link-wrap{
     margin-top: 24px;
     li.page-link{
      border-bottom: 1px solid #D9D9D9;
     }
  }
  .contact-info-wrap{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 24px 0;
  }
  
  /*
   * Slide the menu in from left when the input is checked
   */
  #menuToggle input:checked ~ ul {
    left: 0;
  } 
}