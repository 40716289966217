@media screen and (max-width: 650px) { 
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.common-content h2 {
        margin-top: 20px;
    }    
    .content-area .content-area-inner.cart-page-wrapper .page-container .cart-content-row .cell.left .school-list-container {
        flex-direction: column;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .profile-content .tab_btn_wrapper .tab-content .tab-pane .location-frm .button-wrap {
     align-items: flex-end;
     margin-top: 16px;
   }
    .boxed-content-area.payment-checkout .form-wrap-outer { flex-direction: column;}
    .boxed-content-area.payment-checkout .form-wrap-outer .form-container { width: 100%;}
    .boxed-content-area.payment-checkout .form-wrap-outer .form-container.info-area { padding: 0px;}
    .modal.signin-model .modal-dialog .modal-content .modal-body .timer-details-wrapp .timer-outer-wrapp .timer-container {
        margin-top: 20px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .timer-details-wrapp .timer-outer-wrapp {
        padding: 22px 18px; margin: 16px auto 26px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .timer-details-wrapp {
        margin: 0px 25px 33px;
        width: auto;
    }
    .modal.add-child-model.review-admission-modal .modal-dialog .modal-content .modal-body .accordion.accordion-flush .accordion-item .accordion-collapse .accordion-body .admin-detail-row .admin-detail-cell .item-entry br{
        display: none;
    }
    .boxed-content-area.payment-checkout .form-wrap-outer .redirecting-msg .assist-section-wrapp ul li::before {
        display: none;
    }
    .boxed-content-area.payment-checkout .form-wrap-outer .redirecting-msg .title {
        font-size: 21px;
        line-height: 24px;
        margin: 20px auto 16px;
    }
    .boxed-content-area.payment-checkout .form-wrap-outer .redirecting-msg .loader-wrap {
        margin: 26px auto 25px;
    }
    .boxed-content-area.payment-checkout .form-wrap-outer .redirecting-msg .assist-section-wrapp {
        padding: 20px 20px 8px;
        row-gap: 14px;
    }
    .boxed-content-area.payment-checkout .form-wrap-outer .redirecting-msg .assist-section-wrapp .help-lbl {
        text-align: center;
    }
    .boxed-content-area.payment-checkout .form-wrap-outer .redirecting-msg .assist-section-wrapp ul {
        justify-content: center;
    }
    .content-area .inner-page-container .content-section.about-content-main.mt-n20 {  margin-top: 40px; }
    .boxed-content-area.payment-failed h4 { word-break: break-word;}
    .boxed-content-area.payment-failed .status-contaier-wrapp .payment-details-wrapp .pymt-wrapp .payment-flc-btwn span.status-lbl-failed {
        padding: 0px 16px;
    }
    .boxed-content-area.payment-failed .status-contaier-wrapp .payment-details-wrapp {  padding: 0px 20px 0px; }
    .boxed-content-area.payment-failed .status-contaier-wrapp .payment-details-wrapp .pymt-wrapp .payment-flc-btwn {
        padding-left: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.trns-lbl-wrapp{
            flex-wrap: wrap;
            label{width: auto !important;}
            span{word-break: break-all; line-height: 24px;}
        }
    }
    .boxed-content-area.payment-failed .status-contaier-wrapp .payment-details-wrapp .pymt-wrapp .payment-flc-btwn span.status-lbl-successs {
        padding: 0px 16px;
    }
    .boxed-content-area.payment-failed .status-contaier-wrapp .payment-details-wrapp .pymt-wrapp .payment-flc-btwn label { width: auto; }
    .boxed-content-area.payment-failed .status-contaier-wrapp .payment-details-wrapp .pymt-wrapp {
        flex-direction: column;
    }
    .payfee-model .modal-dialog .modal-content .model-body .card .card-body .list-group .list-group-item.session .list-item-row .list-cell.gry-drk select {
        padding: 10px 32px 10px 10px;
    }
    .payfee-model .modal-dialog .modal-content .modal-header {  padding: 24px 16px 22px; }
    .modal.signin-model .modal-dialog .modal-content .modal-body .px-n30 .model-body-col .model-frm .frm-cell.button-wrap{
        justify-content: flex-end;
        .btn{flex: 1;}
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .footer-btn-wrapp {
        padding: 16px 0px 20px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .px-n30 {
          padding: 0px 20px 20px; width: 100%;
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .px-n30 {
        padding: 0px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .px-n43 {
        padding: 10px 16px 30px; flex-direction: column;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .footer-btn-wrapp {
        flex-direction: column;
        align-items: center;
        row-gap: 12px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .fstchkOut-hdr {
        padding: 16px 16px 0px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .fstchkOut-hdr .fstChkot-logo-container {
        border-radius: 0px;
        position: relative;
        // width: 100%;   
        background: transparent;
        height: auto;   
        // margin-top: -46px;
        margin: -56px auto 0px;
        padding: 30px 20px 14px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .form-wrap {
        padding: 30px 16px;
    }
    .boxed-content-area.payment-failed .status-contaier-wrapp .viewDtls-lbl {
        margin: 18px 13px 28px;
        padding: 13px 16px;
        line-height: 20px;
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .table-wrapper table tbody tr td {
        padding: 16px 22px;
    }
    .payfee-model .modal-dialog .modal-content .model-body {
        margin: 0px !important;
        .card {
            padding: 0px 16px 0px 16px;
        }
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-header {
        padding: 24px 16px 22px;
    }
    .modal .modal-body .pay-fee-student-tags .tag-wrapper {
        width: 100%;
    }
    .modal .modal-body .pay-fee-student-tags {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 16px;
    }
    .modal .modal-body .pay-fee-student-tags .tag-wrapper .tag-item-lbl-wrapp {
        align-items: flex-start;
        flex-direction: column;
        padding: 12px 0px;
        .w-30n { width: 100%; }
        .w-70n {  width: 100%; }
    }
    .modal .modal-body .title-area.stufeedetal .tag-wrapper .tag-item-lbl {
        &.w-30n{width: 100%;}
        &.w-70n{width: 100%;}
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .title-area.stufeedetal{ row-gap: 10px; margin-bottom: 20px;}
    .modal-open .modal-dialog .modal-content .modal-footer {
        padding: 15px 16px;
        justify-content: center;
        row-gap: 6px;
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body {  padding: 0px 16px; }
    .modal .modal-body .title-area.stufeedetal .tag-wrapper .tag-item-lbl-wrapp {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 12px 0px;
    }
    .modal .modal-body .title-area.stufeedetal .tag-wrapper { width: 100%; }
    .modal.signin-model .modal-dialog .modal-content .footer-btn-wrapp {
        padding: 15px 16px;
        justify-content: center;
        row-gap: 6px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-header .fstChkot-logo-container { display: none;}
    .modal-open .modal.signin-model .modal-dialog .modal-content .modal-body {
        padding: 30px 0px 0px;
        margin: 0;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-header.fstchkOut-hdr {  padding: 27px 16px 0px;}
    .admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane.scrll-hdn{
        width: auto;
        overflow: initial;
        overflow-x: unset;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .form-wrap .info-container-wrapp { padding: 16px 10px 16px; }
    .modal.signin-model .modal-dialog .modal-content .modal-body .model-body-col {  margin: 0px auto; width: 100%; }
    .modal.signin-model .modal-dialog .modal-content .modal-body .model-body-col.py-n30{padding: 20px 16px 50px;}
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .title-area {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 6px;
    }
    .inner-page-content .inner-page-right-container .button-wrap {
        display: flex;
        justify-content: flex-start; 
        row-gap: 16px;
        flex-wrap: wrap;
    }
    .modal.enroll-student-model .modal-dialog {  max-width: 100%; }
    .main-container .form-content-wrapper{}
    .main-container .content-area { 
        .content-area-inner{
            &.inner-page-container{
                padding-left: 24px;
                padding-right: 24px;
            }

        }
    }
    .blog-main-wrapper .blog-content-wrap .blog-article-wrapper .article-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .signup-wrapper{
        grid-template-columns: auto;
        width: 100%;
        margin: 0 auto;
        padding: 16px 0;
        .signup-col{
            .infobox-pane{
                margin: 0;
                svg{height: auto;}
            }
            .infobox-inner{
                margin: 8px 0;
                padding: 0;
                h2{font-size: 24px; line-height: 1.8rem; margin-bottom: 16px;}
                h6{font-size: 16px; line-height: 1.4rem;margin-bottom: 16px;}
            }
            &.left{
                margin: 0;
            }
        }
    }
    .map-wrapper .map-search-txtfld {
        position: absolute;
        top: 60px;
        left: 10px;
    }
    .content-area .bc-main-wrap ol.breadcrumb {
        margin-bottom: 12px;
        flex-wrap: wrap;
    }
    .content-area .bc-main-wrap ol.breadcrumb li.active::before {
        margin-left: 5px;
        top: 3px;
    }
    .blog-main-wrapper .blog-content-wrap .blog-article-wrapper .social-share-link {
        padding: 30px 0 30px 0;
        justify-content: center;
        flex-direction: column;
        row-gap: 8px;
        a{
            justify-content: center;
        }
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .application-row .application-inner-wrap .col-item.left {
        width: auto;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .application-row .application-inner-wrap .col-item.right {
        width: auto;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .col-item .school-info-main .school-info-exerpts .school-name {
        text-align: center;
    }
}

@media (max-width: 375px){
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.how-work-wrapp .col.left.common-sidebar {
        padding: 18px;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.left .sidebar-collapsible.accordion .accordion-item .accordion-collapse .accordion-body ul.common-sidebar-list {
        padding: 12px 16px;
    }
    .modal.enroll-student-model .modal-dialog .modal-content .model-body .esf-content-outer .esf-content-inner .no-record-panel {
        padding: 20px;
    }
    .modal.add-child-model.review-admission-modal .modal-dialog .modal-content .modal-body .accordion.accordion-flush .accordion-item .accordion-collapse .accordion-body .tab-wrapper ul.tab-header li.nav-item button.nav-link {
        font-size: 13px;
        padding: 11px;
    }
    .payfee-model .modal-dialog .modal-content .model-body .card .card-body .btn-wrapper {
        justify-content: center;
    }
    .payfee-model .modal-dialog .modal-content .model-body .card .card-body .list-group .list-group-item.session .list-item-row .list-cell.gry-lht {
        padding: 13px 0px 13px 10px;
    }
    .payfee-model .modal-dialog .modal-content .model-body .card .card-body .list-group .list-group-item.session .list-item-row .list-cell.gry-drk {
        padding: 8px 7px;
    }
    .payfee-model .modal-dialog .modal-content .model-body .card .card-body .list-group .list-group-item .list-cell.gry-lht {
        padding: 15px 13px;
    }
    .payfee-model .modal-dialog .modal-content .model-body .card .card-body .list-group .list-group-item .list-cell.gry-drk {
        padding: 15px 20px 15px 10px;
    }
    .payfee-model .modal-dialog .modal-content .model-body .card .card-body .list-group .list-group-item .list-cell.payment-cell {
        padding: 15px 15px;
    }
    .payfee-model .modal-dialog .modal-content .model-body .card .card-body .list-group .list-group-item .list-cell.amount-cell {
        padding: 15px 24px 15px 10px;
    }
    .admmission-sequence-wrap .inner-page-content .inner-page-right-container .fld-row.button-wrap .btn{width: 100%;}
    .modal.enroll-student-model .modal-dialog .modal-content .model-body .esf-content-outer .esf-content-inner .cell-block .enroll-student-frm .btn-wrapper .btn{
        width: 100%; margin-left: 0px !important;
    }
    .modal.enroll-student-model .modal-dialog .modal-content .model-body .esf-content-outer .esf-content-inner .cell-block form input[type="tel"]{
        width: 42px !important; height: 42px !important; margin: 5px !important;
     }
.content-area .school-list-container .school-card .info-list-group .list-group-item .session-wrap .session-info-outer {
    display: flex;
    flex-direction: row;
    column-gap: 3px;
    align-items: center;
    margin-bottom: 5px;
}
.header .loc-cartinfo-nav-wrapper {
    align-items: inherit;
    flex-direction: column;
}
}

@media (max-width: 320px){
    .modal.enroll-student-model .modal-dialog .modal-content .model-body .esf-content-outer .esf-content-inner .no-record-panel .fs-14n{
       font-size: 14px;
    }
    .main-container .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .profile-content .tab_btn_wrapper ul.nav-tabs li.nav-item button.nav-link {
        padding: 8px 7px;
        font-size: 12px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .timer-details-wrapp .timer-outer-wrapp .timer-inner-wrapp {
        row-gap: 10px;
        flex-direction: column;
        label{text-align: center;}
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .timer-details-wrapp .timer-outer-wrapp .timer-container {
        margin-top: 16px;
        flex-direction: column;
        row-gap: 7px;
    }
    .modal-dialog .modal-body .model-body-col .form-container .otp-fld-outer .otp-fields-wrapper.otp-comp .otp-inner-fld div div.otpfield input[type=tel] {
        margin: 0px 10px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .form-wrap .frm-cell .field-group-wrap .react-datepicker__tab-loop .react-datepicker-popper{
        transform: translate3d(3px, 246px, 0px) !important;
    }
.modal.signin-model .modal-dialog .modal-content .modal-body .px-n30 .model-body-col .model-frm .frm-cell.button-wrap{
    flex-direction: column;
    row-gap: 16px;
    .btn{
    width: 100%;
    padding: 10px 42px;
    &:last-child {
    margin-left: 0px;
     }
    }
}
    .content-area label {  font-size: 13px; }
    .modal-dialog .modal-body .model-body-col .form-container .signin-fld-grp .btn{
        font-size: 13px;
    }
    .modal.add-child-model.review-admission-modal .modal-dialog .modal-content .modal-body .accordion.accordion-flush .accordion-item .accordion-collapse.show .accordion-body .admin-detail-row .admin-detail-cell {
        flex-direction: column !important;
    }
    .content-area .content-area-inner .inner-page-content .content-section .page-container .row-wrapper .cell-row .frm-cell select.form-select {
        padding: 0.375rem 1.75rem 0.375rem 0.75rem
    }
    .admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane {
        width: 296px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .modal.enroll-student-model .modal-dialog .modal-content .model-body .esf-content-outer .esf-content-inner .cell-block.validate-otp-block {
        text-align: center;
    }

.blog-main-wrapper .blog-content-wrap .blog-article-wrapper .social-share-link {
    padding: 30px 0 30px 0;
    column-gap: 10px;
}
.modal.signin-model .modal-dialog .modal-content .modal-body .model-body-col .form-container form input {
    width: 46px !important;
    height: 46px !important;
}
.modal.signin-model .modal-dialog .modal-content .modal-body .model-body-col .form-container form .signin-fld-grp input{
    width: 100% !important;
    height: 42px !important;
}
.modal.signin-model .modal-dialog .modal-content .modal-body .model-body-col .form-container form .loginoption .loginoption-cell .form-check-inline .form-check-input{
    width: 1em !important;
    height: 1em !important;
}
}

@media (max-width: 991px){
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .table-wrapper table tbody tr td .status {
        padding: 2px 10px;
        font-size: 12px;
    }
    .modal.confirmation-modal .modal-content .modal-body .table-wrapper .document-tbl td.doc-name span{
        display: flex;
    align-items: center;
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .px-n30 .table-wrapper table thead tr th {
        background: rgba(65, 40, 95, 0.06);
    }
    .modal.Student-fee-model .modal-dialog {
        min-width: 90%;
    }
    .modal.Student-fee-model.Configure-fee-wrapp .modal-dialog .modal-content .modal-body .px-n30 .table-wrapper .table{
        overflow-x: hidden;
        grid-template-columns: minmax(130px, 1fr) minmax(120px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .px-n30 .table-wrapper{
        overflow: hidden;
        width: 100%;
        .table{
            display: grid;
            grid-template-columns: minmax(130px, 1fr) minmax(120px, 1fr) minmax(102px, 1fr) minmax(100px, 1fr) minmax(106px, 1fr) minmax(145px, 1fr) minmax(140px, 1fr);
            min-width: 100%;
            overflow-x: scroll;
            border-collapse: collapse;
            thead, tbody, tr{display: contents;}            
        }
    }
    .modal.enroll-student-model .modal-dialog .modal-content .model-body .esf-content-outer {
        flex-direction: column;
    }
    .content-area .inner-page-container .content-section.bc-section .bc-col.blog-bc {
        flex-direction: column; 
   }
   .content-area .inner-page-container .content-section.bc-section .bc-col.blog-bc .bc-cell.right{
    margin-top: 5px; 
}
.content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .application-row .application-inner-wrap .col-item.right {
    row-gap: 0px;
    display: flex;
    justify-content: space-between;
}
.content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.how-work-wrapp .col.right.common-content {
    margin-left: 0px;
}
.content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.common-content ol {
    width: 100%;
}
.content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.common-content .guide-screen {
    width: 100%;
}
.content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.common-content .guide-screen img{
    width: 100%;
}
}

//  blog page media
@media (min-width: 991px) and (max-width: 1199px){
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.common-content .guide-screen {
        width: 100%;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.common-content .guide-screen img {
        width: 100%;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.how-work-wrapp .col.right.common-content {
        margin-left: 0px;
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .table-wrapper table tbody tr td .status {
        padding: 2px 10px;
        font-size: 12px;
    }
    .modal.confirmation-modal .modal-content .modal-body .table-wrapper .document-tbl td.doc-name span{
        display: flex;
    align-items: center;
    }
    .modal .modal-body .title-area.stufeedetal .tag-wrapper {
        width: 76%;
    }
    .modal.Student-fee-model.Configure-fee-wrapp .modal-dialog .modal-content .modal-body .px-n30 .table-wrapper .table {
        display: grid;
        grid-template-columns: minmax(130px, 1fr) minmax(120px, 1fr) minmax(102px, 1fr) minmax(100px, 1fr);
        min-width: 100%;
        overflow-x: hidden;
        border-collapse: collapse;
    }
    .modal.Student-fee-model .modal-dialog .modal-content .modal-body .px-n30 .table-wrapper{
        overflow: hidden;
        width: 100%;
        .table{
            display: grid;
            grid-template-columns: minmax(130px, 1fr) minmax(120px, 1fr) minmax(102px, 1fr) minmax(100px, 1fr) minmax(106px, 1fr) minmax(145px, 1fr) minmax(140px, 1fr);
            min-width: 100%;
            overflow-x: scroll;
            border-collapse: collapse;
            thead, tbody, tr{display: contents;}            
        }
    }
    .blog-main-wrapper{
        .blog-content-wrap{
          ul.blog-list{
            li{
                flex-basis: 488px;
            }
          }
        }
    }
}
@media (min-width: 768px) and (max-width: 991px){
    .content-area .contact-section-wrapper.pd-md-0n { padding: 0 0%;}
    .modal.signin-model .modal-dialog .modal-content .modal-body .px-n30 {
        padding: 0px 24px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .model-body-col {
        margin: 24px 0px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .footer-btn-wrapp {
        padding: 15px 26px;
    }
    .modal .modal-body .title-area.stufeedetal .tag-wrapper {
        width: 75%; flex: 1;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .form-wrap {
        padding: 30px 26px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .fstchkOut-hdr .Form-header-wrapper .dtls-lbl {
        max-width: 410px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-body .fstchkOut-hdr .Form-header-wrapper .sub-title-hdr {
        max-width: 410px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-header .Form-header-wrapper .sub-title-hdr {
        max-width: 435px;
    }
    .modal.signin-model .modal-dialog .modal-content .modal-header .Form-header-wrapper .dtls-lbl {
        max-width: 435px;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .application-row .application-inner-wrap .col-item.left {
        width: 78%;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .application-row .application-inner-wrap .col-item.right {
        width: 22%;
    }
    .content-area .content-area-inner.profile-page-wrap .inner-page-content .content-section.profile-content-main .col.right.content .row-items.application-block .col-item .school-info-main .school-info-exerpts .school-name {
        width: 413px;
    }
    .blog-main-wrapper{
        .blog-content-wrap{
          ul.blog-list{
            li{
             flex-basis: 360px;
            }
          }
        }
    }

    .footer-main .finner .fcol {
        padding: 16px 1px;
    }
    .footer-main .finner .fcol.btn-container {
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        align-items: flex-start;
        flex-wrap: nowrap;
        justify-content: space-between;
        .btn {
            width: 43%;
        }
    }
}
@media (min-width: 651px){
.modal.signin-model .modal-dialog .modal-content .modal-header{ padding: 16px 24px;}
}
@media (min-width: 376px) and (max-width: 396px){
    .admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane {
        width: 365px !important;
        overflow: hidden;
        overflow-x: scroll;
    }    
}
@media (min-width: 397px) and (max-width: 414px){
    .admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane {
        width: 385px !important;
        overflow: hidden;
        overflow-x: scroll;
    }    
}

@media (min-width: 415px) and (max-width: 550px){
.admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane {
    width: 401px;
    overflow: hidden;
    overflow-x: scroll;
}
}
@media (min-width: 321px) and (max-width: 375px){
    .admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane {
        width: 351px;
        overflow: hidden;
        overflow-x: scroll;
    }
    }

    @media (min-width: 321px) and (max-width: 360px){
        .admmission-sequence-wrap .inner-page-content .inner-page-right-container .supporting-document .tab-content .tab-pane .tab-content .tab-pane {
            width: 336px;
            overflow: hidden;
            overflow-x: scroll;
        }
    }
    @media (min-width: 651px) and (max-width: 1250px){
        .modal .modal-body .title-area.stufeedetal .tag-wrapper .tag-item-lbl{
            &.w-30n {
                flex: 50% 1;
            }
            &.w-70n {
                flex: 50% 1;
            }
        }
        .modal.Student-fee-model.Configure-fee-wrapp .modal-dialog .modal-body .title-area.stufeedetal .tag-wrapper {
            width: 79%; flex: 1;
        }

    .modal.confirmation-modal .modal-content .modal-body .table-wrapper .document-tbl td.doc-upload-fld input {
        width: 100%;
    }
    }
@media (min-width: 1251px){
    .modal.confirmation-modal .modal-content .modal-body .table-wrapper .document-tbl td.doc-name span{
        display: flex;
        justify-content: center;
    align-items: center;
    }
    .modal.confirmation-modal .modal-content .modal-body .table-wrapper .document-tbl td.doc-upload-fld .doc-upload-wrapp{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}