@import '../scss/base';
//@import "../../../pages/signIn/signin.scss";
.App {
  position: relative;
  display: flex;
  // grid-template-rows: auto auto auto;
  // align-items: center;
  // justify-items: center;
}
.print-container {
  th,
  td {
    border: 1px solid black;
    padding: 10px;
    text-align: center;
  }
  .allign-left {
    text-align: left;
  }
}
.main-container {
  position: relative;
  padding: 0;
  &.admin-contianer {
    .content-area {
      &.user-supAdmin{
        min-height: 74vh;
        background: #F8F8F8;
        .navbar-header{
          &.col{flex: 0 0;}
        }
      }
      padding: 0;
      .content-area-inner {
        &.dashboard-wrapper {
          padding: 6px 24px 0px; 
          // padding-top: 24px;
        }
      }
      .title-kpi-wrapper {
        .bc-main-wrap {
          border-bottom: none;
          ol.breadcrumb {
            margin-bottom: 0;
            padding: 12px 0;
          }
        }
      }
    }
  }
  .top-navigation {
    height: 58px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: $primarycolor;
    &.main-site {
      height: 48px;
    }
    .items-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding: 0 24px;
      .item-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        &.brand-logo {
          padding: 2px 8px;
          background: #4c326a;
          border: 1px solid #6e568a;
          box-shadow: 0px 4px 24px #523a6f;
          border-radius: 2px;
          .subscriber-name {
            font-size: 18px;
            font-weight: 800;
            text-transform: uppercase;
            &.subscriber-logo {
            }
          }
        }
        .inner-cell {
          padding: 0 16px;
          border-right: 1px solid rgba(255, 255, 255, 0.6);
          &:last-child {
            border: none;
            padding-right: 0;
          }
          &.profile-info {
            display: flex;
            flex-direction: row;
            .text-white{
              font-size: 14px;
              font-weight: 600;
            }
            .sb-avatar {
              margin-right: 8px;
              &.sb-avatar--text {
              }
            }
          }
          .dropdown {
            a {
              font-weight: 400;
            }
            .dropdown-menu {
              ul {
                a.dropdown-item {
                  color: $primarycolor;
                  &.active {
                    background: $primarycolor;
                    color: $white;
                  }
                }
              }
            }
          }
        }
        label.datetime {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
        }
        a {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
        }
      }
    }
    .navbar_links {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
      span {
        padding-right: 19px;
        border-right: 1px solid #ffffff;
      }
    }
    .unionIcon-border {
      padding: 0px 16px;
      border-left: 1px solid #ffffff;
    }
  }
  .mainmenu-wrap {
    background-color: $white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 0 24px;
    position: relative;

    .navbar-collapse {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .d-flex {
        column-gap: 4px;
        align-items: center;
        label {
          white-space: nowrap;
        }
      }
    }
    .menu-items {
      display: flex;
      flex-direction: row;
      height: 64px;
      align-content: center;
      align-items: center;
      a.nav-link {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #221f1f;
        height: 64px;
        display: flex;
        align-items: center;
        padding: 0 24px;

        &:hover {
          background: rgba(65, 40, 95, 0.08);
        }
        &.active-page {
          background: rgba(65, 40, 95, 0.08);
        }
      }
    }
    .btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 12px 24px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 42px;
      &.guide-btn {
        background: $primarycolor;
        color: $white;
        border: 1px solid $primarycolor;
      }
    }
  }

  .title-kpi-wrapper {
    // background: rgba(65, 40, 95, 0.12);
    // height: 57px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 0 0px 0 24px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: #f1f1f1;
    .bc-nav {
      ol.breadcrumb {
        margin: 0;
        li.breadcrumb-item {
          a {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: rgba(65, 40, 95, 0.8);
          }
          &.active {
            font-weight: 700;
            color: $primarycolor;
          }
        }
      }
    }
    .kpi-wrapper {
      background: rgba(65, 40, 95, 0.84);
      height: 42px;
      display: flex;
      flex-direction: row;
      color: $white;
      padding: 0 24px;
      align-items: center;
      align-content: center;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 42px solid rgba(65, 40, 95, 0.84);
        border-left: 24px solid transparent;
        top: 0;
        left: -24px;
      }

      .kpi-list-group {
        display: flex;
        flex-direction: row;
        .list-group-item {
          background: none;
          padding: 0;
          color: $white;
          border: none;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          border-right: 1px solid rgba(255, 255, 255, 0.4);
          padding: 0 8px;
          border-radius: 0;
          &:last-child {
            border-right: none;
            padding-right: 0;
          }
          label.lbl {
            color: rgba(255, 255, 255, 0.9);
            font-weight: 400;
            margin-bottom: 0;
          }
          .value {
            color: #ffffff;
            font-weight: 600;
          }
          &.application-status {
            display: flex;
            flex-direction: row;
            column-gap: 8px;
            label.lbl {
            }
            .app-status-cell {
              label.lbl {
              }
              .value {
              }
            }
          }
        }
      }
    }
  }
  .application-status {
    &.status-block {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      background: #efefef;
      justify-content: flex-end;
      align-items: center;
      padding: 4px 24px;
      .app-status-cell {
        border-right: 1px solid #bcbdbd;
        padding: 0 16px 0 8px;
        &:last-child {
          border: none;
          padding-right: 0;
        }
        label.lbl {
          font-size: 14px;
        }
      }
    }
  }

  .footer-panel {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
    height: 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    background: #e6e6e6;
    align-items: center;
    justify-content: space-between;
    a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.red {
  background: red;
}
.change-pwd-model {
  .modal-dialog {
    width: 442px;
    max-width: 442px;
    .modal-content {
      display: flex;
      .modal-header {
        font-size: 16px;
        font-weight: 600;
        color: $primarycolor;
        padding: 24px 24px;
      }
      .model-body {
        display: grid;
        padding: 0;
        grid-template-columns: auto;
        .form-container{
          &.forgot-pwd{
            
            .frm-cell{
              &.cpwd-wrap{
                border-bottom: 1px solid #efefef;
                padding-bottom: 16px;
              }
              .lbl{
                font-size: 14px;
                color: rgba(0, 0, 0, 0.8);
                line-height: 18px;
                margin-bottom: 4px;
              }
              .form-select{
                background-color: #FFFFFF;
                border: 1px solid #D9D9D9;
                border-radius: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                height: 44px;
              }
              .pwd-fld-inner{
                position: relative;
                input[type='password'].form-control{
                  background: #FFFFFF;
                  border: 1px solid #D9D9D9;
                  border-radius: 4px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 18px;
                  color: #000000;
                  height: 44px;
                }
                input[type='text'].form-control{
                  background: #FFFFFF;
                  border: 1px solid #D9D9D9;
                  border-radius: 4px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 18px;
                  color: #000000;
                  height: 44px;
                }
                .view-pwd-icon{
                  position: absolute;
                  top: 20%;
                  right: 16px;
                }
              }
            }
          }
        }
        .model-body-col {
          display: flex;
          flex-direction: column;
          h4 {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin-top: 24px;
            padding: 8px 42px;
            line-height: 28px;
          }
          form {
            margin: 0 24px 24px 24px;

            .fld-row {
              display: flex;
              .fld-cell {
                margin-bottom: 16px;
                flex-grow: 1;
                .fld-wrap {
                  display: flex;
                  flex-direction: column;
                  flex-grow: 1;
                  label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: rgba(0, 0, 0, 0.8);
                    .req {
                      color: $darkred;
                    }
                  }
                  input {
                    border-radius: 4px;
                    height: 48px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.8);
                    &:focus {
                      border: 1px solid #41285f;
                      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
                      color: $primarycolor;
                    }
                    &:active {
                      color: $primarycolor;
                      border: 1px solid #41285f;
                      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
                    }
                    &::placeholder {
                      color: rgba(0, 0, 0, 0.25);
                    }
                  }
                  textarea {
                    border-radius: 4px;
                    height: auto;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.8);
                    &:focus {
                      border: 1px solid #41285f;
                      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
                      color: $primarycolor;
                    }
                    &:active {
                      color: $primarycolor;
                      border: 1px solid #41285f;
                      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
                    }
                    &::placeholder {
                      color: rgba(0, 0, 0, 0.25);
                    }
                  }
                }
              }
            }
          }
        }
        .btn-wrapper {
          .btn {
            font-weight: 400;
            &.cancel-btn {
              background: $white;
              border: 1px solid $darkred;
              color: $darkred;
            }
            &.confirm-btn {
              background: $darkgreen;
              border: 1px solid $darkgreen;
              color: $white;
            }
          }
        }
      }
    }
  }
}
/*Header*/

ul.list-group {
  li.list-group-item {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
  }
}

.content-area {
  display: flex;
  flex-direction: column;
  .content-area-inner {
    // padding: 16px 24px;
    //padding: 0;
    .metrics-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 24px;
      // overflow-x: auto;
      .metrics-block {
        // height: 150px;
        height: 100%;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.8);
        position: relative;
        .expand-kta-wrap {
          position: absolute;
          right: 2px;
          top: 2px;
        }
        .title-area {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          padding: 8px 16px 0;
          margin-bottom: 16px;
          h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $primarycolor;
            // color: rgba(0, 0, 0, 0.8);
          }
          .value {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            text-align: right;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        .mlist-group {
          .list-group-item {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 24px;
            justify-content: space-between;
            border-bottom: 1px solid #efefef;
            border-radius: 0;
            border-left: none;
            border-right: none;
            border-top: 1px solid #efefef;

            &:last-child {
              border: none;
            }
            .mitem-wrap {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
            label.lbl {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: rgba(0, 0, 0, 0.85);
              margin-bottom: 0;
            }
            .value {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: rgba(0, 0, 0, 0.85);
            }
          }
        }
        &.mb2 {
          .title-area {
            margin-bottom: 0;
          }
          .list-group-item {
            border: none;
            grid-template-columns: 1fr 2fr;
            justify-content: start;
          }
        }
        &.mb3 {
          .title-area {
            margin-bottom: 0;
            text-align: center;
            justify-content: center;
            h2 {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: $primarycolor;
            }
          }
          .chart-area {
            padding: 0px 24px;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
        }
      }
    }
    .chart-wrap {
      display: grid;
      grid-template-columns: 2.06fr 1fr;
      column-gap: 24px;
      margin-top: 6px;
      overflow-x: auto;
      .chart-block {
        height: 355px;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        .title-area {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 6px 16px 0px;
          &.left-col {
          }
          &.right-col {
          }
          .clist-group {
            display: flex;
            flex-direction: row;
            .list-group-item {
              display: flex;
              flex-direction: column;
              border-right: 1px solid rgba(0, 0, 0, 0.16);
              border-top: none;
              border-bottom: none;
              border-left: none;
              padding: 0 24px;
              &:last-child {
                border-right: none;
                padding-right: 0;
              }
              label {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: rgba(0, 0, 0, 0.6);
                margin-bottom: 0;
              }
              .value {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                text-align: right;
                color: $primarycolor;
              }
            }
          }
          h2 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $primarycolor;
          }
          .value {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            text-align: right;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        &.ch1 {
        }
        &.ch2 {
          .title-area {
            justify-content: center;
            .clist-group {
              .list-group-item {
                display: flex;
                border-right: 1px solid rgba(0, 0, 0, 0.16);
                border-top: none;
                border-bottom: none;
                border-left: none;
                padding: 0 14px;
                flex-direction: column;
                align-items: center;
                align-content: center;
                justify-content: flex-start;
                &:last-child {
                  border-right: none;
                }
                label {
                  text-align: center;
                }
              }
            }
          }
        }
        .chart-area {
          padding: 0px 24px;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }
    }
    &.inner-page-outer {
      padding: 0;
      &.manage-application {
        padding: 0;
      }
    }
  }
  .internal-page-wrapper {
    // display: grid;
    background: #ffffff;

    &.two-columns {
      display: flex;
      flex-direction: row;
    }
    &.full-width {
    }

    .filterpanel {
      border-right: 1px solid rgba(0, 0, 0, 0.08);
      padding: 24px;
      flex: 0 0 264px;
      .filter-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 8px;
        margin-bottom: 16px;
        .filter-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          i {
            margin-right: 8px;
          }
          label {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color: #414d5e;
          }
        }
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          text-align: right;
          color: #f06070;
        }
      }
      .filter-form-area {
        .form-element-group {
          margin-bottom: 16px;
          .react-datepicker-wrapper{
            .react-datepicker__input-container{input{padding: 8px; height: 42px; font-size: 14px;}}
          }
          .react-datepicker__tab-loop{
            .react-datepicker-popper {
              z-index: 2;
              .react-datepicker{
                .react-datepicker__month-container{
                  .react-datepicker__month{
                    .react-datepicker__week{
                      .react-datepicker__day--keyboard-selected{background: #41285F; color: #fff;}
                    }
                  }
                }
              }
            }
          }
          label {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #221f1f;
            margin-bottom: 4px;
          }
          select {
            border: 1px solid rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 16px;
            height: 42px;
            font-size: 14px;
          }
          .inner-container {
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            padding: 8px;
            font-size: 14px;
            &.option-filter {
              display: flex;
              flex-direction: row;
              gap: 20px;
              .form-label {
                margin-bottom: 0;
              }
              .radio-choice {
                display: flex;
                flex-direction: row;
                gap: 8px;
                .form-check {
                  margin: 0;
                  display: flex;
                  flex-direction: row;
                  gap: 4px;
                  align-content: center;
                  align-items: center;
                  .form-check-input {
                    margin-top: 0;
                  }
                  .form-check-label {
                    margin: 0;
                  }
                }
              }
            }
            .options-wrap {
              display: flex;
              flex-direction: row;
              gap: 16px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.08);
              margin-bottom: 8px;
              padding: 8px 0;
              .form-check {
                margin: 0;
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-content: center;
                align-items: center;
                .form-check-input {
                  margin-top: 0;
                }
                label.form-check-label {
                  margin: 0;
                }
              }
            }
            .slider-block {
              display: flex;
              flex-direction: column;
              margin-bottom: 4px;
            }
            .input-val-wrapper {
              display: flex;
              flex-direction: row;
              gap: 24px;
              .value-cell {
                display: flex;
                flex-direction: row;
                gap: 4px;
                label {
                  margin: 0;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  color: rgba(0, 0, 0, 0.8);
                }
                input.form-control {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  color: rgba(0, 0, 0, 0.4);
                }
              }
            }
          }
        }
        .btn-wrap {
          margin-bottom: 0;
          overflow: auto;
          position: -webkit-sticky;
          position: sticky;
          bottom: 1%;
          z-index: 1;
        }
        .btn {
          &.applyFilter {
            background: $primarycolor;
            border: 1px solid $primarycolor;
            color: $white;
            padding: 14px 24px;
            border-radius: 4px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            height: 48px;
          }
        }
      }
      &.sidenav {
        padding: 0;
        min-height: 76vh;
        flex: 0 0 224px;
        padding-left: 0;
        background: #F8F8F8;
        .nav {
          &.flex-column{gap: 10px;}
          a.nav-link {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.85);
            height: 40px;
            &.active {
              background: rgba(65, 40, 95, 0.06);
              border-right: 3px solid #41285f;
              color: $primarycolor;
            }
            &:hover {
              background: rgba(65, 40, 95, 0.06);
            }
          }
        }
      }
    }
    .inner-content-wrap {
      padding: 8px 16px 24px 16px;
      flex: 1;
      background: #fff;
      width: 100%;
      overflow: hidden;
      .super-admin-table-wrapper{
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        td{ 
          min-width: 60px;
          &:first-child{min-width: 10px;}
        }
      }
      &.padt8 {
      }
      &.lateFee-wrapp{
        display: flex;
        flex-direction: column;
        align-items: center;
        .title-area{padding: 7px 0px 15px;}
        .fee-settings-block{
          border-radius: 8px;
          border: 1px solid #41285f;
          padding: 42px;
          box-shadow: 0 0 30px 0 rgba(202, 199, 226, 0.5);
          margin-top: 24px;
          .btn-wrapper{
            .btn{
              height: 38px;
            }
          }
        }
        .amount-main-wrapp{
          &.fee-settings-panel{

          }
          .frm-cell{
            padding-top: 10px;
            label{
              color:#41285f;
              font-weight: 600;
              font-size: 16px;          
            }
          }
          .amount-inner-wrapp{
            display: flex;
            column-gap: 23px;
            margin: 20px 0 25px;
            .item-cell{
              display: flex;
              flex-direction: column;
              flex-basis: 0;
              flex-grow: 1;
            }
            input{ padding: 8px 12px; height: 42px; font-size: 14px; border-radius: 6px;}
            .form-select-sm{
              height: 42px;
              font-size: 14px;
              border-radius: 2px;
            }
            label{color: #000;  margin-bottom: 5px;}
            &.submission-date-wrapp{
            flex-direction: column;
            
            .submission-title{
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #000;    
              margin: 0;
              border: 1px solid #efefef;
              padding: 8px 0;
              text-align: center;
              border-radius: 8px 8px 0 0;
              border-bottom: none;
              background: #efefef;
              position: relative;
              top: 0px;     
            }
            .date-main-container{
              display: grid;
              grid-template-columns: repeat(10, 1fr);
              row-gap: 10px;
              column-gap: 14px;
              border: 1px solid #efefef;
              padding: 16px;
              border-radius: 0 0 8px 8px;  
              .date-inner-container{
                border-radius: 6px;
                padding: 10px 12px;
                text-align: center;
                font-size: 14px;
                font-family: Rubik;
                line-height: 20px;
                letter-spacing: 0.25px;            
              }           
            }
            }
          }
          .date-selection-wrapp{
            border-radius: 6px;
            border: 1px dashed #4AB900;
            background: rgba(74, 185, 0, 0.04);
            margin: 10px 10px 28px 0;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 38px;
            label{text-transform: lowercase; font-weight: 600;}          
          }

        }
        .btn-wrapper{
          justify-content: flex-start;
         .reset-btn:hover{
          background: rgba(0, 0, 0, 0.4);
          border: 1px solid rgba(0, 0, 0, 0.4);
         }
         .save-btn:hover{
           background: #549b43;
           border: 1px solid #549b43;          
         }
        }
      }
      &.fee-type-wrapp{
        .title-area{}
        .fee-accordian-wrapp{
          box-shadow: 0 0 30px 0 rgba(202, 199, 226, 0.5);
          border: 1px solid #41285f;
          border-radius: 8px;
          margin: 16px 0;
          overflow: hidden;
          .accordion-item{
            margin-bottom: 8px;
            border-radius: 6px;
            .accordion-header{
              .accordion-button{
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;                
                border-radius: 6px;
                color: rgba(0, 0, 0, 0.85);
                &::after{
                  transform: rotate(270deg);
                  position: absolute;
                  left: 16px;
                  width: 14px;
                  top: 20px;
                  height: 14px;
                  background-position: center;
                }
                padding: 1rem 3rem;
                &:not(.collapsed){background-color: #41285F; color: #fff; border-radius: 6px 6px 0px 0px;}
                &:not(.collapsed)::after{
                  transform: rotate(360deg); 
                  background-image: url("../img/icons/downArrow-icon.svg");
                  width: 16px;
                  height: 11px;
                  background-position: bottom;
                  margin-top: 3px;
                }
              }
            }
            .accordion-collapse{
              .accordion-body{
                padding: 0px;
                .manage-fee-wrapp{
                  padding-top: 0px;
                  margin: 24px;
                  .table{
                    margin-bottom: 0;
                    thead{
                      tr{
                        background: #666666;
                      }
                      th{}
                    }
                    thead th:last-child{text-align: right;}
                    tbody{
                      td{
                        &:last-child{text-align: right;}
                        .form-control{font-size: 14px;}
                        .form-select-sm, .form-control-sm{font-size: 14px; height: 40px; text-transform: capitalize;}
                        .btn-wrapper{
                          .save-btn:hover{
                            background: #549b43;
                            border: 1px solid #549b43;                            
                          }
                          .reset-btn:hover{
                            background: rgba(0, 0, 0, 0.4);
                            border: 1px solid rgba(0, 0, 0, 0.4);
                          }
                          .decline-btn:hover{
                            background: #e22445;
                            border: 1px solid #e22445;                            
                          }
                          .approval-btn:hover{
                            background: #005db1;
                            border: 1px solid #005db1;                            
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .table-wrapper {
        border-radius: 8px;
        overflow: hidden;
        margin: 16px 0;
        -webkit-box-shadow: 0 0 30px 0 rgba(202,199,226,.5);
        box-shadow: 0 0 30px 0 rgba(202,199,226,.5);
        border: 1px solid #D9D9D9;
        table{
          margin-bottom: 0;
        }
        &.manage-fee-wrapp{
          &.student-fee-wrapp{
            table{
              tbody{
                tr:nth-child(even) {background-color: #f2f2f2;}
                tr td{
                  text-align: left; 
                  padding: 8px 8px;
                  text-transform: capitalize;
                  
                }
                
              }
            }
          }
          &.classesFee{
            table{
              tbody{
                tr td{}
              }
            }
          }
          table {
            thead {
              tr{
                // background: rgba(65, 40, 95, 0.06); 
                background: #41285f;
                color: #fff;
                height: 48px;
                th{
                  text-align: left;
                  padding: 8px;
                }
              }

            }
            tbody{
              tr td{
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;  
                vertical-align: middle;   
                // background: #F8F8F8;
                border-bottom: none;
                // padding: 8px 16px;
                padding: 16px 16px;
                text-align: center;    
                .erro-pos-td{
                  position: relative;
                  .error-exception-cft{
                    position: absolute;
                    left: 0;
                    bottom: -20px;
                  }
                }       
                &.id-cell{
                  width: 60px;
                  vertical-align: middle;
                  // background-color: rgba(65, 40, 95, 0.02);
                  // box-shadow: rgba(0, 0, 0, 0.12) 0px -1px 0px 0px inset;                  
                }
                
                .form-control-sm, .form-select-sm{
                  color: rgba(0, 0, 0, 0.84);
                  font-size: 14px;
                  
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  height: 40px; 
                  padding: 8px 12px;
                  border-radius: 6px; 
                  &:disabled{background-color: #fff;}
                }
              }
              tr:nth-child(even) {
                background: #f0f0f0;
              }
              tr:nth-child(odd) {
                background: #FFF;
              }
              
            }
            .action-btn-wrapper{
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding-right: 8px;
              column-gap: 8px;
              .btn{
                padding: 11px;     
                width: 42px;   
                height: 42px; 
                display: flex;
                align-items: center;
                text-align: center;
                color: #ffffff;  
                border-radius: 100px; 
                transition: all 0.15s ease-in-out;
                i{
                  width: 18px;
                  height: 18px;
                }
                &:hover{opacity: 0.8;}
                &.edit-btn{
                  border: 1px solid #DADADA;
                  background: #4AB900;      
                }  
                &.cancel-btn{
                  background: #E22445;
                  border: 1px solid #E22445;
                }  
                &.dlt-btn{
                  border-radius: 100px;
                  border: 1px solid #E53535;
                  background: #E53535;
                  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);                  
                }
                &.save-btn{
                  background:#335eea;
                  border: 1px solid #6c8aec;
                  // background: #DADADA;
                }   
                &.update-btn{
                  background: #EF9F2C;   
                  border: 1px solid #EF9F2C;               
                }    
              }
            }
          }
        }
        
        table {
          thead {
          }
          tbody {
            tr {
              td {
                a {
                  text-decoration: underline !important;
                  text-decoration-line: underline !important;
                  text-decoration-color: #41285f !important;
                  text-decoration-thickness: 2px !important;
                }
                .dropdown {
                  &.show {
                    .dropdown-menu {
                      a {
                        color: $primarycolor;
                        text-decoration: none !important;
                        .text-info {
                          color: $primarycolor !important;
                        }
                      }
                    }
                  }
                }
                .under-review {
                  color: #005db1;
                }
                .success {
                  color: $darkgreen;
                }
                .rejected {
                  color: $darkred;
                }
              }
            }
          }
        }
      }
      .table-wrapper-outer{
        .pagination{
          display: flex;
          flex-wrap: wrap;
          row-gap: 10px;          
        }
      }
    }
  }
}
.required {
  color: $darkred;
}
.legend-block {
  display: flex;
  column-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  span {
    display: flex;
    height: 12px;
    width: 12px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 8px;
    &.medicalConditions {
      background: #ffbf00;
      color: #ffbf00;
    }
    &.backgroundCheckNegative {
      background: $darkred;
      color: $darkred;
    }
    &.extracurricular {
      background: $darkgreen;
      color: $darkgreen;
    }
    &.none-found {
      font-size: 12px;
      font-weight: 600;
      color: $primarycolor;
      height: auto;
      width: auto;
    }
    &:hover {
      box-shadow: 0px 4px 24px #523a6f;
    }
  }
}

table {
  thead {
    tr {
      th {
        font-size: 14px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 14px;
        button.btn {
          font-size: 14px;
          border: none;
          border-radius: 4px;
          &:hover {
            border: none;
          }
        }
        .approved {
          color: $darkgreen;
        }
        .under-review {
        }
        .received {
          color: #005db1;
        }
        .shortlisted {
          color: #ffbf00;
        }
        .denied {
          color: $darkred;
        }
      }
    }
  }
}

// Text color legends
.blue-label {
  color: #3e8dd5;
  font-weight: 400;
} // Received
.red-label {
  color: $darkred;
  font-weight: 400;
} //Denied, rejected
.green-label {
  color: $darkgreen;
  font-weight: 400;
} //Approved
.orange-label {
  color: #e1ab0a;
  font-weight: 400;
} //under review
.violet-label {
  color: #54b4d3;
  font-weight: 400;
} // AT-PI

.application-form-wrap {
  .fld-row {
    &.disability-row{
      flex-direction: column;
      .expanded-block{
        .disability-list-wrapper{row-gap: 5px;}
        .others-cell{
          margin: 16px 0px;
          .fld-wrap{
            display: flex;
            flex: 1 1;
            flex-direction: column;
            margin-bottom: 16px;
            label {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: rgba(0, 0, 0, 0.8);
              margin-bottom: 4px;
          }    
          .form-control {
            height: 46px;
            font-size: 14px;
            border-radius: 4px;
        }        
          }
        }
      }
    }
    &.flex-colm{flex-direction: column;}
    display: flex;
    // flex-direction: column;
    column-gap: 24px;
    flex-basis: 100%;
    .fld-cell {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 16px;
      .form-select {
        height: 42px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        font-size: 14px;
        text-transform: capitalize;
        option{text-transform: capitalize;}
      }
      label.lbl {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 4px;
        text-transform: initial;
        .req {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #e22445;
          margin-left: 4px;
        }
      }
      &.checkbox-cell {
        .checkbox-fld {
          display: flex;
          flex-direction: row;
          column-gap: 16px;
          height: 42px;
          align-items: center;
          .fld-wrap {
          }
        }
      }

      .fld-wrap {
        label {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.8);
          margin-bottom: 4px;
          .req {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #e22445;
            margin-left: 4px;
          }
        }
        .form-control {
          height: 46px;
          font-size: 14px;
          border-radius: 4px;
          &:disabled {
          }
        }

        .form-select {
          height: 42px;
        }
        textarea {
          height: 42px;
        }
      }
      &.identification-mark-cell {
        margin-bottom: 0;
      }
      &.info-title {
        margin-bottom: 16px;
      }
      .fld-item {
      }
      &.two-row {
        row-gap: 12px;
      }
      .otp-block {
        margin: 0 auto;
        padding: 50px 40px;
        max-width: 550px;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.08);
        background: #FFF;
        box-shadow: 0px 1px 26px 0px rgba(0, 0, 0, 0.1);  
        margin-bottom: 16px;  
        .item-cell {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &.label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            .title-lbl{
              color: #41285F;
              font-family: Rubik;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;            
            }
            .sub-title-lbl{
              color: #000;
              font-family: Rubik;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;              
            }
          }

          &.otpinput-box {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: stretch;
            justify-content: center;
            div{column-gap: 16px;}
            input{
              border-radius: 6px;
              border: 1px solid #41285F;
              background: rgba(255, 255, 255, 0.16); 
              // padding: 18px 23px;
              width: 53px !important;
              height: 53px;
              color: rgba(0, 0, 0, 0.80);
              text-align: center;
              font-family: Rubik;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.14px;                           
            }
          }
        }
      }
      input[type='text'] {
        height: 46px;
        font-size: 14px;
        border-radius: 4px;
        text-transform: capitalize;
      }
    }
    &.check-option {
      .fld-cell {
        display: flex;
        flex-direction: column;
        .title {
          margin-bottom: 8px;
        }
        .option-fld-cell {
          display: flex;
          flex-wrap: wrap;
          row-gap: 8px;
          column-gap: 24px;
          .input-fld {
            // margin-right: 16px;
            display: flex;
            column-gap: 8px;
            input[type='radio'] {
              margin-top: 0;
            }
          }
        }

        textarea.form-control {
          font-size: 14px;
          border-radius: 4px;
          min-height: 46px;
          display: flex;
          padding: 10px 12px;
        }
      }
    }
    &.button-wrap {
      display: flex;
      justify-content: flex-end;
      column-gap: 8px;
      border-top: 1px solid #e6e6e6;
      padding-top: 24px;
    }
    &.expand-block {
      display: flex;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      // border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      // margin-bottom: 16px;
      // margin-top: 16px;
      padding: 8px 0;
      flex-direction: column;
      .expand-block-cell {
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 8px;
        .checkbox-fld {
          display: flex;
          flex-direction: row;
        }
      }

      .expanded-block {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        background: rgba(217, 217, 217, 0.3);
        padding: 24px;
        border-radius: 8px;
        margin-bottom: 8px;
        .disability-list-wrapper {
          margin-bottom: 16px;
        }
        .others-cell {
          .fld-wrap {
            display: flex;
            flex-direction: column;
            flex: 1 1;

            label {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: rgba(0, 0, 0, 0.8);
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
  select {
    option {
      &:disabled {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .additional-frm-block {
    .form-block-wrapper {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      padding: 16px;
      background: rgba(217, 217, 217, 0.3);
    }
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .currentschoolinfo-row {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 8px 0;
    flex-direction: column;
    &.contined-block {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .currentschoolinfo-cell {
      display: flex;
      align-items: center;
      flex-direction: row;
      column-gap: 8px;
      label {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }
    .ifschool-block {
      margin-top: 8px;
      background: rgba(217, 217, 217, 0.3);
      padding: 24px;
      border-radius: 8px;
      margin-bottom: 8px;
    }
  }
}
.location-frm{
  .mt-16n{ margin-top: 16px;}
  // .button-wrap{ margin: 16px 0px;}
}
.Extracurriculars-form-wrapp{
  .participate-checkbox-wrapp{
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    margin: 16px 0px 16px;
    .form-check{
      padding-left: 0px;
      .fld-wrap{
        .lbl{
          display: flex;
          align-items: initial;
        }
      }
    }
  }
  .fld-row{
    column-gap: 24px;
    display: flex;
    .fld-cell{
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;  
      .fld-wrap{
        .form-control{
          border-radius: 4px;
          font-size: 14px;
          height: 46px;
        }
      }    
    }
  }
}
.school-list-container:has(.no-record-wrap) {
  display: flex;
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  &.manageuser-btn {
    .btn {
      height: auto;
      padding: 4px 16px;
      font-size: 13px;
    }
  }
  .btn {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    height: 42px;
    padding: 4px 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
    &:hover {
      opacity: 0.8;
    }
    &.signin-btn {
      background: $primarycolor;
      border: 1px solid $primarycolor;
      color: $white;
      font-weight: 600;
      &:hover {
        color: $white;
      }
    }
    &.approval-btn {
      background: #41285F;
      border: 1px solid #41285F;
      &:hover {
        background: #41285F;
        border: 1px solid #41285F;
      }
    }
    &.decline-btn {
      background: #e22445;
      border: 1px solid #e22445;
      &:hover {
        background: #e22445;
        border: 1px solid #e22445;
      }
    }
    &.accept-btn {
      background: #549b43;
      border: 1px solid #549b43;
      &:hover {
        background: #549b43;
        border: 1px solid #549b43;
      }
    }
    &.reset-btn {
      background: #222222;
      border: 1px solid #222222;
      height: 34px;
      font-size: 12px;
      padding: 4px 16px;
      &:hover {
        background: rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
    }
    &.save-btn {
      background: #549b43;
      border: 1px solid #549b43;
      &:hover {
        background: #549b43;
        border: 1px solid #549b43;
      }
    }
    &.add-btn {
      background: #dadada;
      border: 1px solid #dadada;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 100px;
      height: 40px;
      width: 40px;
      padding: 0;
    }
    &.del-btn {
      background: #e53535;
      border: 1px solid #e53535;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 100px;
      height: 40px;
      width: 40px;
      padding: 0;
    }
  }
}
.title-area {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &.mngfee-title{
    padding: 4px 16px;
    border-radius: 8px;
    border: 1px solid #e6effc;
    background: #f4f6f9;
    margin-top: 8px;
    height: 54px;
    .admission-fld-wrap{
      // border-left: 1px solid #efefef;
      // border-right: 1px solid #efefef;
      // height: 54px;
      display: flex;
      column-gap: 24px;
      .fld-item{
        display: flex;
        align-items: center;
        column-gap: 4px;
      }
    }
    
  }
  .btn-wrapper{
      
      .btn{
        height: 34px;
        &.reset-btn{}
        &.save-btn{}
      }
    }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: $black;
    margin-bottom: 0;
  }
  .admission-fld-wrap {
    display: flex;
    align-items: center;
    column-gap: 8px;

    label {
      white-space: nowrap;
      margin: 0;
    }
    select {
    }
  }
}

.tbl-grid-wrapper {
  margin-top: 8px;
  overflow-x: hidden;
  table {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns:
      minmax(100px, 1fr)
      minmax(90px, 1fr)
      minmax(120px, 1fr)
      minmax(90px, 1fr)
      minmax(90px, 1fr)
      minmax(150px, 3.5fr)
      minmax(150px, 3.5fr)
      minmax(150px, 3.5fr)
      minmax(100px, 1fr)
      minmax(100px, 1fr)
      minmax(120px, 1fr);

    &.user-tbl {
      grid-template-columns:
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        minmax(150px, 1fr);
    }
    thead,
    tbody,
    tr {
      display: contents;
    }

    th,
    td {
      padding: 15px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }

    th {
      position: sticky;
      top: 0;
      // background: rgba(65, 40, 95, 0.06);
      background: #efefef;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      z-index: 2;
      &.action-cell {
        width: 120px;
        text-align: center;
      }
    }

    th:last-child {
      border: 0;
    }
    td {
      display: flex;
      padding-top: 4px;
      padding-bottom: 4px;
      align-items: center;
      justify-content: center;
      height: 72px;
      color: $black;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      column-gap: 4px;
      //position: relative;
      .error-exception {
        font-size: 12px;
        color: $darkred;
        text-align: center;
        margin-top: 0;

        margin-bottom: -18px;
      }
      
      th,
      td {
        padding: 15px 9px;
        overflow: hidden;
        //text-overflow: ellipsis;
        //white-space: nowrap;
      }
      input {
        font-size: 14px;
      }

      .switch-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .form-switch {
          margin: 0;
        }
        label.form-label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin: 0;
          &.no {
            color: rgba(0, 0, 0, 0.4);
            padding-right: 8px;
          }
          &.yes {
            color: #549b43;
          }
        }
      }
      &.action-cell {
        width: 120px;
        .btn {
          border-radius: 60px;
          &.save-btn {
          }
          &.delete-btn {
          }
        }
      }
    }
  }

  tr:nth-child(even) td {
    background: #f8f6ff;
  }
}

.footer-main {
  background: #f3f3f3;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .copyright-row {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding: 4px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 52px;
    padding: 0 26px;
    .copyright-col {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      column-gap: 8px;
      row-gap: 8px;
      flex-wrap: wrap;
      color: #595d69;
      &.fcol3{
        column-gap:24px;
        a{
          position: relative;
          &:last-child{
            &:after{
              display: none;
            }
          }
          &:after {
            bottom: 1px;
            right: -13px;
            border: 1px solid #ababab;
            border-radius: 20px;
            content: '';
            display: inline-block;
            height: 16px;
            width: 1px;
            position: absolute;
            transition: width .3s ease-out;
            
          }
          &:hover{
            text-decoration: underline !important;
          
          }
        }
      }
      a {
        color: #595d69;
      }
    }
  }
}

/*Model Window*/
.modal-dialog {
}

/*SIDEBAR*/
.sidebar-main {
  position: absolute;
  top: 48px;
  z-index: 9;
  .nav-menu {
    background-color: #ffffff;
    width: 224pt;
    height: 100vh;
    display: flex;
    justify-content: center;
    transition: 850ms;
  }
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #2a358c14 8%;
}

.nav-menu-items {
  width: 100%;
  padding-left: 0;
}

.navbar-toggle {
  background-color: #2a358c;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

// span {
// margin-left: 16px;
// }

/*CONTENT WRAPPER*/
.main-content-wrap {
  position: relative;
  top: 48px;
  &.container-fluid {
    padding: 0;
  }
  .content-inner-wrap {
    padding-left: 224pt;
    .breadcrumb-wrapper {
      background: $white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.04);
      padding: 10px 24px;
      ul.cd-breadcrumb {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        li {
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          a {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.45);
            .bg-lbl {
              margin-left: 8px;
            }
            &:hover {
              color: $primarycolor;
            }
          }
          &.current {
          }
        }
      }
    }

    .divider-horizontal {
      margin: 16px 24px;
      background: rgba(0, 0, 0, 0.04);
      flex-direction: row;
      display: flex;
      align-content: center;
      align-items: center;
      height: 1px;
    }

    .section-fluid-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      align-content: center;
      padding: 0 24px;
      margin-bottom: 16px;
      &.column-wrap {
        flex-direction: column;
      }
      &.item-stretch {
        align-items: stretch;
      }
    }
    .button-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
    }
    .btn {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #ffffff;
      justify-content: center;
      &.primary {
        background: $primarycolor;
        border: 1px solid $primarycolor;
      }
    }
  }
}

.range-slider-wrapper {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 16px;
  .multi-range-slider {
    box-shadow: none;
    border: none;
    position: relative;
    z-index: 0;
    .bar {
    }
    .bar-inner {
      .bar-inner-left {
      }
      .bar-inner-right {
      }
    }
    .bar-left {
    }
    .bar-right {
    }
  }
  .income-range-value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #221f1f;
    justify-content: center;
    align-content: center;
    margin-bottom: 8px;
  }
}
.modal {
  &.alert-popup {
    .modal-dialog {
      max-width: 580px;
      .modal-content {
        .modal-body {
          margin: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
          padding-top: 0;
          .alert-content-inner {
            display: flex;
            flex-direction: column;
            flex: 1 0 100%;
            row-gap: 24px;
            .message-content {
              padding: 24px 22px;
            }
            .status-result-grid {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              font-weight: 400;
              column-gap: 24px;
              flex: 1 0 100%;
              .result-row {
                display: grid;
                grid-template-columns: 1fr 3fr;
                grid-column-gap: 24px;
                padding: 8px 16px;
                &.header {
                  background: rgba(217, 217, 217, 0.4);
                  font-weight: 600;
                  color: $primarycolor;
                }
                &.content-body {
                  border-bottom: 1px solid rgba(217, 217, 217, 0.4);
                }
                .cell {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
  &.confirmation-modal {
    &.chart-modal {
      .modal-dialog {
        max-width: 980px;
        .model-body {
          .modal-body-col {
            .metrics-block {
              .title-area {
                justify-content: center;
              }
            }
          }
        }
      }
    }
    .modal-dialog {
      max-width: 740px;
    }
    .modal-content {
      .modal-header {
        font-weight: 700;
        padding: 24px;
        border: 1px solid rgba(0, 0, 0, 0.08);
        text-transform: capitalize;
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        .table-wrapper{
          overflow: hidden;
          width: 100%;
          .document-tbl{
          }
        }
        .model-body-col {
          .message-content {
            label {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              text-transform: capitalize;
              color: rgba(0, 0, 0, 0.8);
            }
            textarea {
              background: rgba(0, 0, 0, 0.02);
              border: 1px solid rgba(0, 0, 0, 0.08);
              border-radius: 6px;
            }
          }
        }
        .button-wrapper {
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          // margin-top: 16px;
          .btn {
            background: #41285f;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
            border-radius: 4px;
            height: 44px;
            color: $white;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            padding: 4px 24px;
            text-transform: uppercase;
            &.cancel-btn {
              background: rgba(68, 68, 68, 0.6);
            }
            &.save-btn {
            }
          }
        }
      }
    }
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@import '../scss/media.scss';
